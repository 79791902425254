import { createStore } from 'vuex'

export default createStore({
  state: {
    go_to: "home",
    language: 'UA',
    lang_arr: {}
  },
  mutations: {
    SCROLL_TO: (state, item) => {
      state.go_to = item
    },
    CHANGE_LANGUAGE: (state, item) => {
      state.language = item
    },
    CHANGE_LANG_ARR: (state, item) => {
      state.lang_arr = item
    }
  },
  actions: {
    SCROLL({commit}, item) {
      commit('SCROLL_TO', item)
    },
    CHANGE_LANG({commit}, item) {
      commit('CHANGE_LANGUAGE', item)
    },
    GET_LANG_ARR({commit}, item) {
      commit('CHANGE_LANG_ARR', item)
    }
  },
  getters: {
    GO_TO(state) {
      return state.go_to
    },
    LANGUAGE(state) {
      return state.language
    },
    LANG_ARR(state) {
      return state.lang_arr
    }
  }
})
