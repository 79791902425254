<template>
  <Viking />
  <Comunications />
  <WhyBarbaro />
  <Slider />
  <Contacts />
</template>

<script>
import Viking from "@/components/viking.vue";
import Comunications from "@/components/comunications-component.vue";
import WhyBarbaro from "@/components/why-barbaro.vue";
import Slider from "@/components/slider.vue";
import Contacts from "@/components/contacts.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    Viking,
    Comunications,
    WhyBarbaro,
    Slider,
    Contacts,
  },

};
</script>
