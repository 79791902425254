<template>
  <div id="contacts">
    <div class="container">
      <div class="row contacts-container">
        <div class="col-12 col-md-5 viking-with-mobile">
          <img
            v-if="flash"
            class="flash"
            :src="require('../assets/images/flash2.png')"
            alt=""
          />
          <img
            :src="require('../assets/images/viking-with-mobile.png')"
            alt=""
          />
        </div>
        <div :class="{ flashbg: flash }" class="col-12 col-md-6 contacts">
          <div class="title">{{ this.LANG_ARR.contacts[0].title }}</div>
          <div class="mail-phone">
            <p class="tooltip">{{ this.LANG_ARR.contacts[0].copy }}</p>
            <div class="mail">
              <!--               <input
                id="email"
                type="text"
                value="barbaro.communications@gmail.com"
              /> -->
              <div class="text">barbaro.communications@gmail.com</div>
              <div
                @click="copyText('barbaro.communications@gmail.com')"
                class="letter"
              >
                <i class="far fa-envelope"></i>
              </div>
            </div>
            <div class="phone">
              <!-- <input id="phone" type="text" value="+38-093-418-78-33" /> -->
              <div class="text">+38-093-418-78-33</div>
              <div @click="copyText('+38-093-418-78-33')" class="letter">
                <i class="fas fa-phone-volume"></i>
              </div>
            </div>
          </div>
          <div class="button">
            <button @click="linkTo()">
              {{ this.LANG_ARR.contacts[0].button }}
              <img :src="require('../assets/images/button-arrow.png')" alt="" />
            </button>
          </div>
          <div class="description">
            {{ this.LANG_ARR.contacts[0].footer }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "contacts",
  data() {
    return {
      flash: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.offset);
  },
  methods: {
    offset() {
      if (pageYOffset >= 4000) {
        this.flash = true;
      } else {
        this.flash = false;
      }
    },
    copyText(text) {
      /* let copyText = document.querySelector(id); */
      navigator.clipboard
        .writeText(text)
        .then(() => {
          let tooltip = document.querySelector(".tooltip");
          tooltip.classList.add("show");
          setTimeout(() => tooltip.classList.remove("show"), 500);
        })
        .catch((err) => {
          console.log("Something went wrong", err);
        });
      /*       copyText.select();
      document.execCommand("copy"); */
    },
    linkTo() {
      window.location = "https://calendly.com/barbaro-communications";
    },
  },
  computed: {
    ...mapGetters(["LANG_ARR"]),
  },
};
</script>

<style lang="scss" scoped>
#contacts {
  overflow: hidden;
}

.tooltip {
  position: absolute;
  top: -30px;
  width: 100%;
  z-index: 9999;
  color: #000;
  text-align: center;
}

#email,
#phone {
  position: absolute;
  left: 10000px;
}

.tooltip.show {
  opacity: 1;
  animation: showTooltip 0.5s linear;
}

@keyframes showTooltip {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.contacts-container {
  align-items: center;
  justify-content: space-between;
  .viking-with-mobile {
    position: relative;
    margin-bottom: -30px;
    img {
      width: 100%;
    }
    .flash {
      position: absolute;
      top: -10%;
      left: 40%;
    }
  }
  .contacts {
    .title {
      font-style: italic;
      font-weight: bold;
      font-size: 80px;
      line-height: 143.9%;
    }
    .mail-phone {
      margin: 20px 0 40px;
      position: relative;
      .mail {
        display: flex;
        border-top: 2px solid #000;
        justify-content: space-between;
        .text {
          padding: 15px 0;
          font-style: normal;
          font-weight: 600;
          font-size: 25px;
          line-height: 30px;
        }
        .letter {
          background: #ffcd1c;
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2px 20px;
        }
      }
      .phone {
        display: flex;
        border-top: 2px solid #000;
        border-bottom: 2px solid #000;
        justify-content: space-between;
        .text {
          padding: 15px 0;
          font-style: normal;
          font-weight: 600;
          font-size: 25px;
          line-height: 30px;
        }
        .letter {
          background: #fff;
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2px 22px;
        }
      }
    }
    .button {
      button {
        padding: 20px;
        background: #ff6b00;
        border: none;
        outline: none;
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 30px;
        opacity: 0.7;
        border-radius: 4px;
        transition-duration: 0.5s;
        color: rgb(0, 0, 0);
        &:hover {
          opacity: 1;
          text-decoration-line: underline;
        }
        img {
          margin-left: 10px;
          width: 15px;
        }
      }
    }
    .description {
      width: 75%;
      margin-top: 30px;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.flash {
  animation: flashlight 0.5s linear;
  animation-fill-mode: forwards;
}

@keyframes flashlight {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

.flashbg {
  animation: flashbg 3s linear;
  animation-fill-mode: forwards;
}

@keyframes flashbg {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1215px) {
  .contacts-container {
    align-items: center;
    justify-content: space-between;
    .viking-with-mobile {
      position: relative;
      margin-bottom: -30px;
      img {
        width: 100%;
      }
      .flash {
        position: absolute;
        top: -10%;
        left: 40%;
      }
    }
    .contacts {
      .title {
        font-style: italic;
        font-weight: bold;
        font-size: 50px;
        line-height: 143.9%;
      }
      .mail-phone {
        margin: 20px 0 40px;
        .mail {
          display: flex;
          border-top: 2px solid #000;
          justify-content: space-between;
          .text {
            padding: 15px 0;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
          }
          .letter {
            background: #ffcd1c;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2px 20px;
          }
        }
        .phone {
          display: flex;
          border-top: 2px solid #000;
          border-bottom: 2px solid #000;
          justify-content: space-between;
          .text {
            padding: 15px 0;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
          }
          .letter {
            background: #fff;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2px 22px;
          }
        }
      }
      .button {
        button {
          padding: 10px;
          background: #ff6b00;
          border: none;
          outline: none;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 30px;
          opacity: 0.7;
          border-radius: 4px;
          transition-duration: 0.5s;
          &:hover {
            opacity: 1;
            text-decoration-line: underline;
          }
          img {
            margin-left: 10px;
            width: 15px;
          }
        }
      }
      .description {
        width: 75%;
        margin-top: 30px;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .contacts-container {
    flex-direction: column-reverse;
    .contacts {
      .title {
        text-align: center;
        font-style: italic;
        font-weight: bold;
        font-size: 50px;
        line-height: 143.9%;
      }
      .mail-phone {
        margin: 20px 0 40px;
        .mail {
          display: flex;
          border-top: 2px solid #000;
          justify-content: space-between;
          .text {
            padding: 5px 0;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 30px;
          }
          .letter {
            background: #ffcd1c;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2px 13px;
          }
        }
        .phone {
          display: flex;
          border-top: 2px solid #000;
          border-bottom: 2px solid #000;
          justify-content: space-between;
          .text {
            padding: 5px 0;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 30px;
          }
          .letter {
            background: #fff;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2px 15px;
          }
        }
      }
      .button {
        text-align: center;
        button {
          padding: 20px;
          background: #ff6b00;
          border: none;
          outline: none;
          font-style: normal;
          font-weight: 500;
          font-size: 25px;
          line-height: 30px;
          opacity: 0.7;
          border-radius: 4px;
          transition-duration: 0.5s;
          &:hover {
            opacity: 1;
            text-decoration-line: underline;
          }
          img {
            margin-left: 10px;
            width: 15px;
          }
        }
      }
      .description {
        width: 100%;
        text-align: center;
        margin-top: 30px;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>