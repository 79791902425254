<template>
    <WhoAre />
    <Mission />
    <Vision />
</template>

<script>
import WhoAre from "@/components/who-are.vue"
import Mission from "@/components/mission.vue"
import Vision from "@/components/vision.vue"
export default {
    components: {
        WhoAre,
        Mission,
        Vision
    }
}
</script>

<style lang="scss" scoped>

</style>