<template>
  <div id="why-barbaro">
    <div id="media">
      <div class="parallax-container desctop">
        <img
          class="bg-sun"
          :src="require('../assets/images/bg-sun.png')"
          alt=""
        />
        <div class="texture"></div>
        <div class="container content">
          <transition name="slide-fade-one">
            <div v-if="parOn_1" class="row justify-content-between">
              <div class="col-3 square">
                <div class="star text-end">
                  <img :src="require('../assets/images/star-1.png')" alt="" />
                </div>
                <div class="square_content">
                  <div class="square_container">
                    <div class="number">1000+</div>
                    <div class="text">{{this.LANG_ARR.why_barbaro[0].square1}}</div>
                  </div>
                </div>
              </div>
              <div class="col-4 title">
                <p>{{this.LANG_ARR.why_barbaro[0].title}}</p>
              </div>
            </div>
          </transition>
          <transition name="slide-fade-two">
            <div v-if="parOn_2" class="row">
              <div class="col-3"></div>
              <div class="col-3 square middle-square">
                <div class="square_wrapper">
                  <div class="square-number">18</div>
                  <div class="square-text text-end">
                    <img :src="require('../assets/images/star-2.png')" alt="" />
                    <div class="square-description">
                      {{this.LANG_ARR.why_barbaro[0].square2}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <transition name="slide-fade-three">
            <div v-if="parOn_3" class="row">
              <div class="col-3 square">
                <div class="square_wrapper">
                  <div class="square-number-left">8</div>
                  <div class="square-text-left text-end">
                    <img :src="require('../assets/images/star-2.png')" alt="" />
                    <div class="square-description-left">
                      {{this.LANG_ARR.why_barbaro[0].square3}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3"></div>
              <div class="col-3 square square-right">
                <div class="star text-end">
                  <img :src="require('../assets/images/star-3.png')" alt="" />
                </div>
                <div class="square_content">
                  <div class="square_container">
                    <div class="number">500k+</div>
                    <div class="text">{{this.LANG_ARR.why_barbaro[0].square4}}</div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div class="container footer-content">
          <div class="row">
            <div class="col-6 media">
              <div class="media-title">{{this.LANG_ARR.why_barbaro[0].subtitle}}</div>
              <div class="row">
                <div class="col-6">
                  <div v-if="print_text" class="print-text">{{this.LANG_ARR.why_barbaro[0].media1}}</div>
                </div>
                <div class="col-6">
                  <div v-if="print_text" class="print-text">{{this.LANG_ARR.why_barbaro[0].media2}}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div v-if="print_text" class="print-text">{{this.LANG_ARR.why_barbaro[0].media3}}</div>
                </div>
                <div class="col-6">
                  <div v-if="print_text" class="print-text">{{this.LANG_ARR.why_barbaro[0].media4}}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div v-if="print_text" class="print-text">{{this.LANG_ARR.why_barbaro[0].media5}}</div>
                </div>
                <div class="col-6">
                  <div v-if="print_text" class="print-text">
                    {{this.LANG_ARR.why_barbaro[0].media6}}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div v-if="print_text" class="print-text">{{this.LANG_ARR.why_barbaro[0].media7}}</div>
                </div>
                <div class="col-6">
                  <div v-if="print_text" class="print-text">{{this.LANG_ARR.why_barbaro[0].media8}}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div v-if="print_text" class="print-text">{{this.LANG_ARR.why_barbaro[0].media9}}</div>
                </div>
                <div class="col-6">
                  <div v-if="print_text" class="print-text">{{this.LANG_ARR.why_barbaro[0].media10}}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div v-if="print_text" class="print-text">{{this.LANG_ARR.why_barbaro[0].media11}}</div>
                </div>
                <div class="col-6">
                  <div v-if="print_text" class="print-text">{{this.LANG_ARR.why_barbaro[0].media12}}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div v-if="print_text" class="print-text">
                    {{this.LANG_ARR.why_barbaro[0].media13}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 battleship">
              <img
                class="bs-sun"
                :src="require('../assets/images/bs-sun.png')"
                alt=""
              />
              <img
                class="bs"
                :src="require('../assets/images/battleship.png')"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mobile">
        <div class="title">{{this.LANG_ARR.why_barbaro[0].title}}</div>
        <div class="container square-container">
          <transition name="square-left">
            <div v-if="sq_left" class="square">
              <div class="square-star">
                <img :src="require('../assets/images/star-1.png')" alt="" />
              </div>
              <div class="square-content">
                <div class="number">1000+</div>
                <div class="description">{{this.LANG_ARR.why_barbaro[0].square1}}</div>
              </div>
            </div>
          </transition>
          <transition name="square-right">
            <div v-if="sq_right" class="square">
              <div class="square-star">
                <img :src="require('../assets/images/star-3.png')" alt="" />
              </div>
              <div class="square-content">
                <div class="number">500k</div>
                <div class="description">{{this.LANG_ARR.why_barbaro[0].square4}}</div>
              </div>
            </div>
          </transition>
        </div>
        <div class="container square-container">
          <transition name="square-left">
            <div v-if="sq_left" class="square">
              <div class="square-star">
                <img :src="require('../assets/images/star-2.png')" alt="" />
              </div>
              <div class="square-content">
                <div class="number">18</div>
                <div class="description">{{this.LANG_ARR.why_barbaro[0].square2}}</div>
              </div>
            </div>
          </transition>
          <transition name="square-right">
            <div v-if="sq_right" class="square">
              <div class="square-star">
                <img :src="require('../assets/images/star-1.png')" alt="" />
              </div>
              <div class="square-content">
                <div class="number">8</div>
                <div class="description">
                  {{this.LANG_ARR.why_barbaro[0].square3}}
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div class="container footer-content">
          <div class="row">
            <div class="col-12 media">
              <div class="media-title">{{this.LANG_ARR.why_barbaro[0].subtitle}}</div>
              <div class="row">
                <div class="col-6">
                  <div v-if="print_text_mb" class="print-text">{{this.LANG_ARR.why_barbaro[0].media1}}</div>
                </div>
                <div class="col-6">
                  <div v-if="print_text_mb" class="print-text">{{this.LANG_ARR.why_barbaro[0].media2}}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div v-if="print_text_mb" class="print-text">{{this.LANG_ARR.why_barbaro[0].media3}}</div>
                </div>
                <div class="col-6">
                  <div v-if="print_text_mb" class="print-text">{{this.LANG_ARR.why_barbaro[0].media4}}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div v-if="print_text_mb" class="print-text">{{this.LANG_ARR.why_barbaro[0].media5}}</div>
                </div>
                <div class="col-6">
                  <div v-if="print_text_mb" class="print-text">
                    {{this.LANG_ARR.why_barbaro[0].media6}}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div v-if="print_text_mb" class="print-text">{{this.LANG_ARR.why_barbaro[0].media7}}</div>
                </div>
                <div class="col-6">
                  <div v-if="print_text_mb" class="print-text">
                    {{this.LANG_ARR.why_barbaro[0].media8}}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div v-if="print_text_mb" class="print-text">{{this.LANG_ARR.why_barbaro[0].media9}}</div>
                </div>
                <div class="col-6">
                  <div v-if="print_text_mb" class="print-text">{{this.LANG_ARR.why_barbaro[0].media10}}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div v-if="print_text_mb" class="print-text">{{this.LANG_ARR.why_barbaro[0].media11}}</div>
                </div>
                <div class="col-6">
                  <div v-if="print_text_mb" class="print-text">{{this.LANG_ARR.why_barbaro[0].media12}}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div v-if="print_text_mb" class="print-text">
                    {{this.LANG_ARR.why_barbaro[0].media13}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "whyBarbaro",
  data() {
    return {
      parOn_1: false,
      parOn_2: false,
      parOn_3: false,
      print_text: false,
      sq_left: false,
      sq_right: false,
      print_text_mb: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.offset);
    window.addEventListener("scroll", this.offset_mobile);
  },
  methods: {
    offset() {
      /* let element = document.querySelector('.parallax-container') */
      /* console.log(pageYOffset); */
      if (pageYOffset >= 1200) {
        this.parOn_1 = true;
      } else {
        this.parOn_1 = false;
      }
      if (pageYOffset >= 1400) {
        this.parOn_2 = true;
      } else {
        this.parOn_2 = false;
      }
      if (pageYOffset >= 1500) {
        this.parOn_3 = true;
      } else {
        this.parOn_3 = false;
      }
      if (pageYOffset >= 1600) {
        this.print_text = true;
      } else {
        this.print_text = false;
      }
    },
    offset_mobile() {
      if (pageYOffset >= 300) {
        this.sq_left = true;
        this.sq_right = true;
      } else {
        this.sq_left = false;
        this.sq_right = false;
      }
      if (pageYOffset >= 500) {
        this.print_text_mb = true;
      } else {
        this.print_text_mb = false;
      }
    },
  },
  computed: {
    ...mapGetters(["LANG_ARR"]),
  },
};
</script>

<style lang="scss" scoped>
#why-barbaro {
  padding: 80px 0;
  position: relative;
}

.desctop {
  .black-circle {
    width: 100%;
  }

  .content {
    padding-top: 150px !important;
  }

  .cirlce-container {
    position: absolute;
    top: 0;
    z-index: 2;
    width: 100%;
    /*   
  max-height: 2400px; */
  }

  .square {
    border: 2px solid #fff;
    z-index: 3;
    padding: 20px;
    height: 320px;
    width: 380px;
  }

  .title {
    display: flex;
    justify-content: flex-end;
    z-index: 3;
    font-style: italic;
    font-weight: bold;
    font-size: 80px;
    line-height: 143.9%;
    color: #fff;
  }

  .square_content {
    .square_container {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .number {
      font-style: normal;
      font-weight: bold;
      font-size: 80px;
      line-height: 110px;
      transform: rotate(-90deg);
      color: #ffffff;
      width: 30%;
      margin-top: 100px;
    }
    .text {
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 146.9%;
      color: #ffffff;
      width: 55%;
      margin-top: 60px;
    }
  }

  .bg-sun {
    position: absolute;
    top: 10%;
    left: 10%;
  }

  .footer-content {
    margin-top: 100px;
    .media {
      border: 1px solid #fff;
      padding: 0;
      .media-title {
        padding: 20px;
        text-align: center;
        background: #fff;
        font-weight: 600;
        font-size: 30px;
        color: #201e1c;
      }
      .row {
        margin: 0;
        padding: 0;
        .col-6,
        .col-12 {
          padding: 10px;
          color: rgba(255, 255, 255, 1);
          font-style: normal;
          font-weight: 500;
          font-size: 25px;
          border-bottom: 1px solid #fff;
          &:nth-child(1) {
            border-right: 1px solid #fff;
          }
        }
      }
    }
    .battleship {
      border: 1px solid #fff;
      width: 46.4% !important;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .bs-sun {
        position: absolute;
        top: -30%;
        left: -18%;
        opacity: 0.7;
      }
      .bs {
        width: 70%;
        height: 80%;
      }
    }
  }

  .middle-square,
  .square-right {
    margin-left: 82px;
  }

  .square_wrapper {
    display: flex;
    .square-number {
      font-style: normal;
      font-weight: bold;
      font-size: 90px;
      line-height: 110px;
      color: #fff;
      width: 25%;
    }
    .square-number-left {
      width: 18%;
      font-style: normal;
      font-weight: bold;
      font-size: 90px;
      line-height: 110px;
      color: #fff;
    }
    .square-text-left {
      width: 82%;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 37px;
      color: #fff;
      .square-description-left {
        margin-top: 60px;
        text-align: start;
      }
    }
    .square-text {
      width: 72%;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 37px;
      color: #fff;
      .square-description {
        margin-top: 120px;
        text-align: start;
      }
    }
  }
}

.parallax-container {
  background: #201e1c;
  position: relative;
  padding: 0 0 150px;
  overflow: hidden;
}

.texture {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/Dirty-Surface-Texture-03.png");
  opacity: 0.7;
}

.mobile {
  display: none;
  background: #201e1c;
  overflow: hidden;
  padding: 40px 0;
  .title {
    color: #fff;
    font-size: 25px;
    font-weight: bold;
    font-style: italic;
    text-align: center;
    margin-bottom: 20px;
  }
  .square-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .square {
      width: 155px;
      height: 120px;
      border: 2px solid #fff;
      padding: 10px;
      margin: 15px 0;
      .square-star {
        display: flex;
        justify-content: flex-end;
        img {
          width: 20px;
        }
      }
      .square-content {
        display: flex;
        flex-direction: column;
        /*         margin-top: 20px; */
        .number {
          color: #fff;
          font-weight: bold;
          font-size: 30px;
          text-align: center;
        }
        .description {
          color: #fff;
          font-size: 10px;
          text-align: center;
        }
      }
    }
  }
  .media {
    border: 1px solid #fff;
    padding: 0;
    margin-top: 40px;
    .media-title {
      padding: 15px;
      text-align: center;
      background: #fff;
      font-weight: 600;
      font-size: 20px;
      color: #201e1c;
    }
    .row {
      margin: 0;
      padding: 0;
      .col-6,
      .col-12 {
        padding: 5px 10px;
        color: rgba(255, 255, 255, 1);
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        border-bottom: 1px solid #fff;
        &:nth-child(1) {
          border-right: 1px solid #fff;
        }
      }
    }
  }
}

.slide-fade-one-enter-active {
  transition: all 2s ease-out;
}

.slide-fade-one-leave-active {
  transition: all 2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-one-enter-from,
.slide-fade-one-leave-to {
  transform: translateX(200px);
  opacity: 0;
}

.slide-fade-two-enter-active {
  transition: all 2s ease-out;
}

.slide-fade-two-leave-active {
  transition: all 2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-two-enter-from,
.slide-fade-two-leave-to {
  transform: translateX(-200px);
  opacity: 0;
}

.slide-fade-three-enter-active {
  transition: all 2s ease-out;
}

.slide-fade-three-leave-active {
  transition: all 2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-three-enter-from,
.slide-fade-three-leave-to {
  transform: translateY(200px);
  opacity: 0;
}

.square-left-enter-active {
  transition: all 2s ease-out;
}

.square-left-leave-active {
  transition: all 2s cubic-bezier(1, 0.5, 0.8, 1);
}

.square-left-enter-from,
.square-left-leave-to {
  transform: translateX(-200px);
  opacity: 0;
}

.square-right-enter-active {
  transition: all 2s ease-out;
}

.square-right-leave-active {
  transition: all 2s cubic-bezier(1, 0.5, 0.8, 1);
}

.square-right-enter-from,
.square-right-leave-to {
  transform: translateX(200px);
  opacity: 0;
}

.print-text {
  width: 24ch;
  font-family: monospace;
  white-space: nowrap;
  overflow: hidden;
  animation: printed-text 5s steps(24);
}

@keyframes printed-text {
  from {
    width: 0;
  }
}

@media screen and (max-width: 1215px) {
  .desctop {
    .square {
      border: 2px solid #fff;
      z-index: 3;
      padding: 20px;
      height: 280px;
      width: 310px;
      .square_content {
        .square_container {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
        .number {
          font-style: normal;
          font-weight: bold;
          font-size: 60px;
          line-height: 110px;
          transform: rotate(-90deg);
          color: #ffffff;
          width: 30%;
          margin-top: 60px;
        }
        .text {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 146.9%;
          color: #ffffff;
          width: 55%;
          margin-top: 70px;
        }
      }
    }
    .middle-square,
    .square-right {
      margin-left: 75px;
    }
    .square_wrapper {
      display: flex;
      .square-number {
        font-style: normal;
        font-weight: bold;
        font-size: 70px;
        line-height: 110px;
        color: #fff;
        width: 25%;
      }
      .square-number-left {
        width: 18%;
        font-style: normal;
        font-weight: bold;
        font-size: 90px;
        line-height: 110px;
        color: #fff;
      }
      .square-text-left {
        width: 82%;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 37px;
        color: #fff;
        .square-description-left {
          margin-top: 60px;
          text-align: start;
        }
      }
      .square-text {
        width: 72%;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 37px;
        color: #fff;
        .square-description {
          margin-top: 100px;
          text-align: start;
        }
      }
    }
  }

  #why-barbaro {
    padding: 40px 0;
    position: relative;
  }
}

@media screen and (max-width: 1000px) {
  .desctop {
    display: none;
  }
  .mobile {
    display: block;
  }
}
</style>