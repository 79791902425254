<template>
  <div id="comunications">
    <div class="container desctop">
      <div class="title">
        <span>BARBARO</span> {{ this.LANG_ARR.comunication[0].title }}
      </div>
      <div class="steps">
        <div class="step">
          <span>01</span>
          <div class="description">
            {{ this.LANG_ARR.comunication[0].step1 }}
          </div>
        </div>
        <div class="step">
          <span>02</span>
          <div class="description">
            {{ this.LANG_ARR.comunication[0].step2 }}
          </div>
        </div>
        <div class="step">
          <span>03</span>
          <div class="description">
            {{ this.LANG_ARR.comunication[0].step3 }}
          </div>
        </div>
      </div>
      <div class="steps-desc">
        <div class="step-desc" v-html="this.LANG_ARR.comunication[0].step_desc1">
        </div>
        <div class="step-desc" v-html="this.LANG_ARR.comunication[0].step_desc2">
        </div>
        <div class="step-desc" v-html="this.LANG_ARR.comunication[0].step_desc3">
        </div>
      </div>
    </div>
    <div class="container mobile">
      <div class="title">
        <span>BARBARO</span> {{ this.LANG_ARR.comunication[0].title }}
      </div>
      <div class="steps">
        <div class="step">
          <div class="step-title">
            <span>01</span>
            <div class="description">{{ this.LANG_ARR.comunication[0].step1 }}</div>
          </div>
          <div class="step-description" v-html="this.LANG_ARR.comunication[0].step_desc1">
          </div>
        </div>
        <div class="step">
          <div class="step-title">
            <span>02</span>
            <div class="description">{{ this.LANG_ARR.comunication[0].step2 }}</div>
          </div>
          <div class="step-description" v-html="this.LANG_ARR.comunication[0].step_desc2">
          </div>
        </div>
        <div class="step">
          <div class="step-title">
            <span>03</span>
            <div class="description">{{ this.LANG_ARR.comunication[0].step3 }}</div>
          </div>
          <div class="step-description" v-html="this.LANG_ARR.comunication[0].step_desc3">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "comunications",
  computed: {
    ...mapGetters(["LANG_ARR"]),
  },
};
</script>

<style lang="scss" scoped>
#comunications {
  padding: 80px 0;
  position: relative;
}

.desctop {
  .title {
    font-size: 40px;
    line-height: 57px;
    font-style: italic;
    font-weight: bold;
    span {
      font-style: italic;
      font-weight: bold;
      font-size: 80px;
    }
  }
  .steps {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #201e1c;
    .step {
      width: 25%;
      position: relative;
      text-align: end;
      &:nth-child(2) {
        width: 24%;
        .description {
          top: 40%;
        }
      }
      &:nth-child(3) {
        width: 29%;
      }
      span {
        font-style: normal;
        font-weight: bold;
        font-size: 150px;
        line-height: 183px;
        color: #ff6b00;
      }
      .description {
        text-align: start;
        position: absolute;
        top: 30%;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 143.9%;
      }
    }
  }
  .steps-desc {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #201e1c;
    padding: 30px 0;
    .step-desc {
      width: 25%;
      position: relative;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      &:nth-child(2) {
        width: 24%;
        .description {
          top: 40%;
        }
      }
      &:nth-child(3) {
        width: 29%;
      }
    }
  }
}

.mobile {
  display: none;
  .title {
    text-align: center;
    font-size: 20px;
    font-style: italic;
    font-weight: bold;
    margin-bottom: 20px;
    span {
      font-style: italic;
      font-weight: bold;
      font-size: 30px;
    }
  }
  .steps {
    display: flex;
    flex-direction: column;
    .step {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      border-bottom: 2px solid #201e1c;
      .step-title {
        position: relative;
        width: 30%;
        span {
          position: absolute;
          left: 25%;
          font-size: 55px;
          font-style: normal;
          font-weight: bold;
          color: #ff6b00;
          z-index: 2;
          opacity: 0.7;
        }
        .description {
          position: relative;
          text-align: left;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          margin-top: 5px;
          z-index: 99;
        }
      }
      .step-description {
        width: 60%;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
      }
    }
  }
}

@media screen and (max-width: 1215px) {
  .desctop {
    .title {
      text-align: center;
      font-size: 28px;
      line-height: 57px;
      font-style: italic;
      font-weight: bold;
      span {
        font-style: italic;
        font-weight: bold;
        font-size: 40px;
      }
    }
    .steps {
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid #201e1c;
      .step {
        width: 25%;
        position: relative;
        text-align: end;
        &:nth-child(2) {
          width: 24%;
          .description {
            top: 40%;
          }
        }
        &:nth-child(3) {
          width: 29%;
        }
        span {
          font-style: normal;
          font-weight: bold;
          font-size: 100px;
          line-height: 183px;
          color: #ff6b00;
        }
        .description {
          text-align: start;
          position: absolute;
          top: 30%;
          font-style: normal;
          font-weight: 600;
          font-size: 25px;
          line-height: 143.9%;
        }
      }
    }
    .steps-desc {
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid #201e1c;
      padding: 30px 0;
      .step-desc {
        width: 25%;
        position: relative;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        &:nth-child(2) {
          width: 24%;
          .description {
            top: 40%;
          }
        }
        &:nth-child(3) {
          width: 29%;
        }
      }
    }
  }
  #comunications {
    padding: 40px 0;
    position: relative;
  }
}

@media screen and (max-width: 1000px) {
  .desctop {
    display: none;
  }
  .mobile {
    display: block;
  }
}
</style>