<template>
  <div id="vision">
    <div class="container">
      <div
        class="row justify-content-between align-items-center first-container"
      >
        <div class="col-12 col-sm-6 text-container">
          <div class="title">{{ this.LANG_ARR.vision[0].title1 }}</div>
          <div class="description">
            {{ this.LANG_ARR.vision[0].description1 }}
            <br />
            <br />
            {{ this.LANG_ARR.vision[0].description2 }}
          </div>
        </div>
        <div class="col-12 col-sm-5">
          <div class="image">
            <img :src="require('../assets/images/vision-image.png')" alt="" />
          </div>
        </div>
      </div>
      <div
        class="row justify-content-between align-items-center second-container"
      >
        <div class="col-12 col-sm-5">
          <div class="image">
            <img :src="require('../assets/images/axes-image.png')" alt="" />
          </div>
        </div>
        <div class="col-12 col-sm-6 text-container">
          <div class="title">{{ this.LANG_ARR.vision[0].title2 }}</div>
          <div class="description">
            {{ this.LANG_ARR.vision[0].description3 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "vision",
  computed: {
    ...mapGetters(["LANG_ARR"]),
  },
};
</script>

<style lang="scss" scoped>
#vision {
  padding: 100px 0;
}

.title {
  font-style: italic;
  font-weight: bold;
  font-size: 70px;
  color: #201e1c;
  margin: 0 0 20px;
}

.description {
  margin-top: 20px;
  color: #201e1c;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 143.9%;
}

.first-container,
.second-container {
  margin: 120px 0;
}

.image {
  img {
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .title {
    font-size: 35px;
    text-align: center;
  }
  .description {
    font-size: 14px;
  }
  #vision {
    padding: 40px 0;
  }
}

@media screen and (max-width: 520px) {
  .first-container {
    position: relative;
    padding: 30px 0;
    margin: 50px 0;
    .image {
      position: absolute;
      top: 0;
      z-index: 1;
      opacity: 0.5;
      display: flex;
      justify-content: center;
      img {
        width: 70%;
      }
    }
    .text-container {
      position: relative;
      z-index: 2;
    }
  }
  .second-container {
    position: relative;
    padding: 30px 0;
    margin: 120px 0;
    .image {
      position: absolute;
      top: 15%;
      z-index: 1;
      opacity: 0.3;
      display: flex;
      justify-content: center;
      img {
        width: 90%;
      }
    }
  }
}
</style>