<template>
  <div id="wrapper">
    <div class="container desctop">
      <div class="logo">
        <router-link class="nav-item" to="/">
          <!-- <img :src="require('../assets/images/logo.png')" alt=""/> -->
          <div class="logo-container">
            <img :src="
              require('../assets/images/Barbaro_logo_horizontal_white.png')
            " alt="" />
          </div>
        </router-link>
      </div>
      <div class="navbar">
        <router-link v-for="item in this.LANG_ARR.wrapper" :key="item.idx" class="nav-item"
          @click="change_page(item.id, item.offset)" :to="item.link">{{ item.name }}</router-link>
      </div>
      <div class="language-dropdown" @click="open_language()">
        <span class="title-drop">UA</span>
        <div class="dropdown-list">
          <span @click="change_language('UA')">UA</span>
          <span @click="change_language('ENG')">ENG</span>
        </div>
      </div>
      <div class="social">
        <a href="https://www.facebook.com/barbaro.communications" target="_blank" rel="noopener noreferrer"><i
            class="fab fa-facebook"></i></a>
        <a href="https://www.instagram.com/barbaro.communications/" target="_blank" rel="noopener noreferrer"><i
            class="fab fa-instagram"></i></a>
      </div>
    </div>
    <div class="container mobile">
      <div class="burger-menu">
        <transition name="hide-logo">
          <div v-if="!isNav" class="logo">
            <router-link class="nav-item" to="/">
              <!-- <img :src="require('../assets/images/logo.png')" alt="" /> -->
              <div class="logo-container">
                <img :src="
                  require('../assets/images/Barbaro_logo_horizontal_white.png')
                " alt="" />
              </div>
            </router-link>
            <div style="margin-left: 10px" class="language-dropdown" @click="open_language()">
              <span class="title-drop mob-title">UA</span>
              <div class="dropdown-list mob">
                <span @click="change_language('UA')">UA</span>
                <span @click="change_language('ENG')">ENG</span>
              </div>
            </div>
          </div>
        </transition>
        <transition name="show-nav">
          <div v-if="isNav" class="burger-nav">
            <router-link v-for="item in this.LANG_ARR.wrapper" :key="item.idx" class="burger-nav-item"
              @click="change_page(item.id, item.offset_mob)" :to="item.link">{{ item.name }}</router-link>
          </div>
        </transition>
        <div class="burger-arrow">
          <i @click="openNav()" class="fas fa-align-justify"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import lang_json from "@/assets/lang/locale.json";
import { mapActions, mapGetters } from "vuex";
import axios from "axios"

export default {
  name: "wrapper",
  data() {
    return {
      isNav: false,
      nav: [],
      curr_page: "",
    };
  },
  mounted() {
    let user_lang = localStorage.getItem('lang')
    if (user_lang == null) {
/*       axios.get('http://ipwho.is')
        .then(response => {
          if (response.success) {
            let user_country = response.data.country_code
            user_country !== "UA" ? this.change_language("ENG") : this.change_language("UA")
          } else {
            this.change_language("ENG")
          }

        })
        .catch(error => {
          console.log(error)
        }) */
        this.change_language("UA")
    } else {
      this.GET_LANG_ARR(lang_json[user_lang][0]);
      let dropdown_title = document.querySelector(".title-drop");
      let mob_dropdown_title = document.querySelector('.mob-title')
      dropdown_title.textContent = user_lang;
      mob_dropdown_title.textContent = user_lang
    }

  },
  methods: {
    ...mapActions(["SCROLL", "CHANGE_LANG", "GET_LANG_ARR"]),
    openNav() {
      this.isNav = !this.isNav;
    },
    change_page(id, offset) {
      this.SCROLL(id);
      if (this.GO_TO != "other_page" && this.GO_TO != "home") {
        setTimeout(() => this.scrollTo(offset), 100);
      } else {
        this.scrollTo(id);
      }
    },
    open_language() {
      let dropdown = document.querySelector(".dropdown-list");
      let mobDropdown = document.querySelector(".mob")
      dropdown.classList.toggle("active");
      mobDropdown.classList.toggle("active");
    },
    change_language(lang) {
      this.CHANGE_LANG(lang);
      let dropdown_title = document.querySelector(".title-drop");
      let mob_dropdown_title = document.querySelector('.mob-title')
      dropdown_title.textContent = lang;
      mob_dropdown_title.textContent = lang
      this.GET_LANG_ARR(lang_json[this.LANGUAGE][0]);
      localStorage.setItem('lang', lang);
    },
    scrollTo(offset) {
      window.scrollTo(0, offset);
    },
  },
  computed: {
    ...mapGetters(["GO_TO", "LANGUAGE", "LANG_ARR"]),
  },
};
</script>

<style lang="scss" scoped>
#wrapper {
  padding: 50px 0;
  z-index: 10;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  width: 170px;
  height: 35px;
  background: #4e7701;
  border-radius: 18px;
  padding: 3px 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }
}

.mobile {
  display: none;

  .burger-menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .none {
      display: none;
    }

    .burger-arrow {
      width: 10%;
      text-align: end;

      i {
        font-size: 25px;
      }
    }

    .burger-nav {
      /* display: none; */
      /*       flex-direction: column;
      position: absolute;
      text-align: end;
      top: 50px;
      left: 60%; */
      width: 90%;
      /* background: #fff; */
      /* padding: 10px; */
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      overflow: hidden;
      height: 35px;

      .burger-nav-item {
        width: max-content;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 24px;
        color: #201e1c;
        text-decoration: none;
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;

        &:hover {
          color: rgba(32, 30, 28, 0.35);
        }
      }
    }

    .active {
      display: flex !important;
    }
  }
}

.show-nav-enter-active {
  transition: all 0.2s linear;
}

.show-nav-leave-active {
  transition: all 0.2s linear;
}

.show-nav-enter-from,
.show-nav-leave-to {
  transform: translateX(200px);
  opacity: 0;
}

.hide-logo-enter-active {
  transition: all 0.2s linear;
}

.hide-logo-leave-active {
  transition: all 0.2s linear;
}

.hide-logo-enter-from,
.hide-logo-leave-to {
  transform: translateX(-200px);
  opacity: 0;
}

.logo {
  width: 20%;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.navbar {
  width: 60%;
  display: flex;
  justify-content: space-evenly;

  .nav-item {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #201e1c;
    text-decoration: none;

    &:hover {
      color: rgba(32, 30, 28, 0.35);
    }
  }
}

.social {
  width: 20%;
  text-align: end;

  a {
    margin: 0 8px;
    font-size: 25px;
    cursor: pointer;
    color: rgb(63, 63, 63);

    &:hover {
      color: rgba(32, 30, 28, 0.35);
    }
  }
}

.language-dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown-list {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 25px;
  height: 0px;
  overflow: hidden;
  transition-duration: 0.5s;
}

.dropdown-list.active {
  height: 50px !important;
}

.dropdown-list span {
  cursor: pointer;
  transition-duration: 0.5s;

  &:hover {
    color: #b1a4a4;
  }
}

@media screen and (max-width: 1215px) {
  .navbar {
    width: 75%;
  }

  .social {
    width: 15%;
  }
}

@media screen and (max-width: 1000px) {
  .desctop {
    display: none;
  }

  .mobile {
    display: flex;
  }

  #wrapper {
    padding: 15px 0 50px;
  }
}
</style>