import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/about.vue'
import Team from '../views/team.vue'
import Services from '../views/services.vue'
import Memes from '../views/memes.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/team',
    name: 'Team',
    component: Team
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/memes',
    name: 'Memes',
    component: Memes
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'hash',
  routes: routes,
  render: (h) => h(App),
})

export default router
