<template>
    <div id="memes">
        <div class="meme-popup" v-if="this.meme_popup">
            <i class="fas fa-chevron-left" @click="next_meme('prev')"></i>
            <div class="meme-popup-container">
                <div class="texture"></div>
                <div class="content flex-column flex-md-row d-flex align-items-center">
                    <div class="col-12 col-md-6">
                        <img :src="require(`../assets/images/memes/${this.active_meme.img}`)" alt=""
                            v-if="active_meme.img">
                        <video autoplay controls :src="require(`../assets/images/memes/${active_meme.video}`)"
                            v-if="active_meme.video"></video>
                    </div>
                    <div class="col-12 col-md-6 p-0 px-md-4">
                        <div class="date d-flex align-items-center">
                            <div class="date-title">
                                {{ this.LANG_ARR.memes[1].title_2 }}
                            </div>
                            <div class="date-value pl-2">
                                {{ this.active_meme.date }}
                            </div>
                        </div>
                        <div class="history">
                            <div class="history-title">
                                {{ this.LANG_ARR.memes[1].title_3 }}
                            </div>
                            <div class="history-description">
                                {{ this.active_meme.history }}
                                <span v-if="!this.active_meme.history">
                                    Опис цього мему і так зрозумілий 😁, але якщо ви маєте свій варіант, надсилайте нам.
                                </Span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <i class="fas fa-chevron-right" @click="next_meme('next')"></i>
            <i class="fas fa-times" @click="this.meme_popup = !this.meme_popup" style="z-index: 100;"></i>


        </div>
        <div class="black-container">
            <img class="bg-sun" :src="require('../assets/images/bg-sun.png')" alt="" />
            <div class="texture"></div>
            <div class="container content" v-html="this.LANG_ARR.memes[0].title_1">
            </div>
        </div>
        <div class="green-container">
            <div class="texture"></div>
            <div class="container content">
                {{ this.LANG_ARR.memes[0].title_2 }}
            </div>
        </div>
        <div class="memes-content">
            <div class="container">
                <div class="text-block">
                    <div class="title">
                        {{ this.LANG_ARR.memes[0].text_block_1_title }}
                    </div>
                    <div class="description">
                        {{ this.LANG_ARR.memes[0].text_block_1_description }}
                        <div class="subdesc">
                            {{ this.LANG_ARR.memes[0].text_block_1_description_1 }}
                        </div>
                        <div class="subul">
                            {{ this.LANG_ARR.memes[0].text_block_1_description_2 }}
                        </div>
                        <div class="subul">
                            {{ this.LANG_ARR.memes[0].text_block_1_description_3 }}
                        </div>
                        <div class="subul">
                            {{ this.LANG_ARR.memes[0].text_block_1_description_4 }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid slider-container">
                <div class="flex-column flex-md-row d-flex">
                    <div class="col-12 col-md-3 date text-end" style="border-right: 3px solid #54791A;">
                        <img :src="require('../assets/images/arrow-left.png')" alt="">
                        <div class="title mt-5">
                            {{ this.LANG_ARR.memes[1].title_1 }}
                        </div>
                        <div class="subtitle">
                            2022
                        </div>
                    </div>
                    <div class="col-12 col-md-8 memes-slider">
                        <div class="preloaded row" :class="{ active: is_show_more_1 }">
                            <div class="meme-item col-6 col-md-3" v-for="item in memes_1" :key="item"
                                @click="open_meme(item)">
                                <img :src="require(`../assets/images/memes/${item.img}`)" alt="" v-if="item.img">
                                <video :src="require(`../assets/images/memes/${item.video}`)" v-if="item.video"></video>
                            </div>
                        </div>
                        <div class="open-all" v-if="!is_show_more_1">
                            <div class="button">
                                <div class="cool_btn" @click="this.is_show_more_1 = !is_show_more_1">
                                    {{ this.LANG_ARR.memes[1].show_more }}
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div class="container-fluid slider-container">
                <div class="flex-column flex-md-row d-flex">
                    <div class="col-12 col-md-8 memes-slider order-last order-md-first">
                        <div class="preloaded row" :class="{ active: is_show_more_2 }">
                            <div class="meme-item col-6 col-md-3" v-for="item in memes_2" :key="item"
                                @click="open_meme(item)">
                                <img :src="require(`../assets/images/memes/${item.img}`)" alt="" v-if="item.img">
                                <video :src="require(`../assets/images/memes/${item.video}`)" v-if="item.video"></video>
                            </div>
                        </div>
                        <div class="open-all" v-if="!is_show_more_2">
                            <div class="button">
                                <div class="cool_btn" @click="this.is_show_more_2 = !is_show_more_2">
                                    {{ this.LANG_ARR.memes[1].show_more }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-3 date text-start order-first order-md-last px-3"
                        style="border-left: 3px solid #54791A;">
                        <img :src="require('../assets/images/arrow-right.png')" alt="">
                        <div class="title mt-5">
                            {{ this.LANG_ARR.memes[1].title_4 }}
                        </div>
                        <div class="subtitle">
                            2022
                        </div>
                    </div>
                </div>

            </div>
            <div class="container-fluid slider-container">
                <div class="flex-column flex-md-row d-flex">
                    <div class="col-12 col-md-3 date text-end" style="border-right: 3px solid #54791A;">
                        <img :src="require('../assets/images/arrow-left.png')" alt="">
                        <div class="title mt-5">
                            {{ this.LANG_ARR.memes[1].title_5 }}
                        </div>
                        <div class="subtitle">
                            2022
                        </div>
                    </div>
                    <div class="col-12 col-md-8 memes-slider">
                        <div class="preloaded row" :class="{ active: is_show_more_3 }">
                            <div class="meme-item col-6 col-md-3" v-for="item in memes_3" :key="item"
                                @click="open_meme(item)">
                                <img :src="require(`../assets/images/memes/${item.img}`)" alt="" v-if="item.img">
                                <video :src="require(`../assets/images/memes/${item.video}`)" v-if="item.video"></video>
                            </div>
                        </div>
                        <div class="open-all" v-if="!is_show_more_1">
                            <div class="button">
                                <div class="cool_btn" @click="this.is_show_more_3 = !is_show_more_3">
                                    {{ this.LANG_ARR.memes[1].show_more }}
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div class="container-fluid slider-container">
                <div class="flex-column flex-md-row d-flex">
                    <div class="col-12 col-md-8 memes-slider order-last order-md-first">
                        <div class="preloaded row" :class="{ active: is_show_more_4 }">
                            <div class="meme-item col-6 col-md-3" v-for="item in memes_4" :key="item"
                                @click="open_meme(item)">
                                <img :src="require(`../assets/images/memes/${item.img}`)" alt="" v-if="item.img">
                                <video :src="require(`../assets/images/memes/${item.video}`)" v-if="item.video"></video>
                            </div>
                        </div>
                        <!--                         <div class="open-all" v-if="!is_show_more_2">
                            <div class="button">
                                <div class="cool_btn" @click="this.is_show_more_4 = !is_show_more_4">
                                    {{ this.LANG_ARR.memes[1].show_more }}
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="col-12 col-md-3 date text-start order-first order-md-last px-3"
                        style="border-left: 3px solid #54791A;">
                        <img :src="require('../assets/images/arrow-right.png')" alt="">
                        <div class="title mt-5" v-html="this.LANG_ARR.memes[1].title_6">
                        </div>
                        <div class="subtitle">
                            2022
                        </div>
                    </div>
                </div>

            </div>
            <div class="container-fluid slider-container">
                <div class="flex-column flex-md-row d-flex">
                    <div class="col-12 col-md-3 date text-end" style="border-right: 3px solid #54791A;">
                        <img :src="require('../assets/images/arrow-left.png')" alt="">
                        <div class="title mt-5" v-html="this.LANG_ARR.memes[1].title_7">
                        </div>
                        <div class="subtitle">
                            2022
                        </div>
                    </div>
                    <div class="col-12 col-md-8 memes-slider">
                        <div class="preloaded row" :class="{ active: is_show_more_5 }">
                            <div class="meme-item col-6 col-md-3" v-for="item in memes_5" :key="item"
                                @click="open_meme(item)">
                                <img :src="require(`../assets/images/memes/${item.img}`)" alt="" v-if="item.img">
                                <video :src="require(`../assets/images/memes/${item.video}`)" v-if="item.video"></video>
                            </div>
                        </div>
                        <div class="open-all" v-if="!is_show_more_1">
                            <div class="button">
                                <div class="cool_btn" @click="this.is_show_more_5 = !is_show_more_5">
                                    {{ this.LANG_ARR.memes[1].show_more }}
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div class="container mt-5">
                <div class="text-block">
                    <div class="title text-center">
                        {{ this.LANG_ARR.memes[2].text_block_1_title }}
                    </div>
                    <div class="description text-center">
                        {{ this.LANG_ARR.memes[2].text_block_1_description }}
                    </div>
                    <div class="button mt-5">
                        <a class="cool_btn" href="https://forms.gle/dEfwmyMNdaKKFsK89" target="_blank" rel="noopener noreferrer">{{
                                this.LANG_ARR.memes[2].cont
                        }}</a>
                    </div>
                </div>
            </div>
            <div class="container mt-5">
                <div class="comment">
                    <div class="title">
                        {{ this.LANG_ARR.memes[3].comment_1_title }}
                    </div>
                    <div class="description">
                        <div class="col-4">
                            <img style="width: 165px;" :src="require('../assets/images/big-star.png')" alt="" />
                        </div>
                        <div class="col-5">
                            <div class="name text-end">
                                {{ this.LANG_ARR.memes[3].comment_1_name }}
                            </div>
                            <div class="prof">
                                {{ this.LANG_ARR.memes[3].comment_1_prof }}
                            </div>
                        </div>
                    </div>
                    <div class="comment-text">
                        {{ this.LANG_ARR.memes[3].comment_1_text_1 }}
                    </div>
                    <div class="full-comment" v-if="full_comment == 1">
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_1_text_2 }}
                        </div>
                        <div class="comment-title">
                            {{ this.LANG_ARR.memes[3].comment_1_title_2 }}
                        </div>
                        <div class="comment-subtitle">
                            {{ this.LANG_ARR.memes[3].comment_1_subtitle_1 }}
                        </div>
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_1_text_3 }}
                        </div>
                        <div class="comment-subtitle">
                            {{ this.LANG_ARR.memes[3].comment_1_subtitle_2 }}
                        </div>
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_1_text_4 }}
                        </div>
                        <div class="comment-subtitle">
                            {{ this.LANG_ARR.memes[3].comment_1_subtitle_3 }}
                        </div>
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_1_text_5 }}
                        </div>
                        <div class="comment-subtitle">
                            {{ this.LANG_ARR.memes[3].comment_1_subtitle_4 }}
                        </div>
                        <div class="comment-title">
                            {{ this.LANG_ARR.memes[3].comment_1_title_3 }}
                        </div>
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_1_text_6 }}
                        </div>
                        <div class="d-flex comment-ul">
                            <div class="col-3">
                                <img style="width: 65px;" :src="require('../assets/images/big-star.png')" alt="" />
                            </div>
                            <div class="col-9" v-html="this.LANG_ARR.memes[3].comment_1_text_7">

                            </div>
                        </div>
                        <div class="d-flex comment-ul">
                            <div class="col-3">
                                <img style="width: 65px;" :src="require('../assets/images/big-star.png')" alt="" />
                            </div>
                            <div class="col-9" v-html="this.LANG_ARR.memes[3].comment_1_text_8">

                            </div>
                        </div>
                        <div class="d-flex comment-ul">
                            <div class="col-3">
                                <img style="width: 65px;" :src="require('../assets/images/big-star.png')" alt="" />
                            </div>
                            <div class="col-9" v-html="this.LANG_ARR.memes[3].comment_1_text_9">

                            </div>
                        </div>
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_1_text_10 }}
                        </div>
                    </div>
                    <div class="button" v-if="full_comment !== 1">
                        <div class="cool_btn" @click="this.full_comment = 1">
                            {{ this.LANG_ARR.memes[1].show_more }}
                        </div>
                    </div>
                </div>
                <div class="comment">
                    <div class="title">
                        {{ this.LANG_ARR.memes[3].comment_2_title }}
                    </div>
                    <div class="description">
                        <div class="col-4">
                            <img style="width: 165px;" :src="require('../assets/images/big-star.png')" alt="" />
                        </div>
                        <div class="col-5">
                            <div class="name text-end">
                                {{ this.LANG_ARR.memes[3].comment_2_name }}
                            </div>
                            <div class="prof">
                                {{ this.LANG_ARR.memes[3].comment_2_prof }}
                            </div>
                        </div>
                    </div>
                    <div class="comment-text">
                        {{ this.LANG_ARR.memes[3].comment_2_text_1 }}
                    </div>
                    <div class="full-comment" v-if="full_comment == 2">
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_2_text_2 }}
                        </div>
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_2_text_3 }}
                        </div>
                        <div class="d-flex justify-content-center mt-5">
                            <video autoplay loop muted :src="require(`../assets/images/vid-1.mp4`)"
                                style="width:50%; margin: 0 auto;"></video>
                        </div>
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_2_text_4 }}
                        </div>
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_2_text_5 }}
                        </div>
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_2_text_6 }}
                        </div>
                        <div class="d-flex justify-content-center mt-5">
                            <video autoplay loop muted :src="require(`../assets/images/vid-2.mp4`)"
                                style="width:50%; margin: 0 auto;"></video>
                        </div>
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_2_text_7 }}
                        </div>
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_2_text_8 }}
                        </div>
                        <div class="comment-text" v-html="this.LANG_ARR.memes[3].comment_2_text_10">
                        </div>
                    </div>
                    <div class="button" v-if="full_comment !== 2">
                        <div class="cool_btn" @click="this.full_comment = 2">
                            {{ this.LANG_ARR.memes[1].show_more }}
                        </div>
                    </div>
                </div>
                <div class="comment">
                    <div class="title">
                        {{ this.LANG_ARR.memes[3].comment_3_title }}
                    </div>
                    <div class="description">
                        <div class="col-4">
                            <img style="width: 165px;" :src="require('../assets/images/big-star.png')" alt="" />
                        </div>
                        <div class="col-5">
                            <div class="name text-end">
                                {{ this.LANG_ARR.memes[3].comment_3_name }}
                            </div>
                            <div class="prof">
                                {{ this.LANG_ARR.memes[3].comment_3_prof }}
                            </div>
                        </div>
                    </div>
                    <div class="comment-text">
                        {{ this.LANG_ARR.memes[3].comment_3_text_1 }}
                    </div>
                    <div class="full-comment" v-if="full_comment == 3">
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_3_text_2 }}
                        </div>
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_3_text_3 }}
                        </div>
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_3_text_4 }}
                        </div>
                        <div class="d-flex justify-content-center mt-5">
                            <img style="width: 50%" :src="require('../assets/images/memes/comment-meme-1.png')"
                                alt="" />
                        </div>
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_3_text_5 }}
                        </div>
                        <div class="d-flex justify-content-center mt-5">
                            <img style="width: 50%" :src="require('../assets/images/memes/comment-meme-2.png')"
                                alt="" />
                        </div>
                        <div class="comment-text" v-html="this.LANG_ARR.memes[3].comment_3_text_6">
                        </div>
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_3_text_7 }}
                        </div>
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_3_text_8 }}
                        </div>
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_3_text_9 }}
                        </div>
                    </div>
                    <div class="button" v-if="full_comment !== 3">
                        <div class="cool_btn" @click="this.full_comment = 3">
                            {{ this.LANG_ARR.memes[1].show_more }}
                        </div>
                    </div>
                </div>
                <div class="comment">
                    <div class="title">
                        {{ this.LANG_ARR.memes[3].comment_4_title }}
                    </div>
                    <div class="description">
                        <div class="col-4">
                            <img style="width: 165px;" :src="require('../assets/images/big-star.png')" alt="" />
                        </div>
                        <div class="col-5">
                            <div class="name text-end">
                                {{ this.LANG_ARR.memes[3].comment_4_name }}
                            </div>
                            <div class="prof">
                                {{ this.LANG_ARR.memes[3].comment_4_prof }}
                            </div>
                        </div>
                    </div>
                    <div class="comment-text">
                        {{ this.LANG_ARR.memes[3].comment_4_text_1 }}
                    </div>
                    <div class="full-comment" v-if="full_comment == 4">
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_4_text_2 }}
                        </div>
                        <div class="comment-title">
                            {{ this.LANG_ARR.memes[3].comment_4_title_2 }}
                        </div>
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_4_text_3 }}
                        </div>
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_4_text_4 }}
                        </div>
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_4_text_5 }}
                        </div>
                        <div class="comment-title">
                            {{ this.LANG_ARR.memes[3].comment_4_title_3 }}
                        </div>
                        <div class="d-flex">
                            <div class="col-1">
                                <img style="width: 45px;" :src="require('../assets/images/big-star.png')" alt="" />
                            </div>
                            <div class="col-11 comment-text m-0">
                                {{ this.LANG_ARR.memes[3].comment_4_text_7 }}
                            </div>
                        </div>
                        <div class="d-flex my-5">
                            <div class="col-1">
                                <img style="width: 45px;" :src="require('../assets/images/big-star.png')" alt="" />
                            </div>
                            <div class="col-11 comment-text m-0">
                                {{ this.LANG_ARR.memes[3].comment_4_text_8 }}
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="col-1">
                                <img style="width: 45px;" :src="require('../assets/images/big-star.png')" alt="" />
                            </div>
                            <div class="col-11 comment-text m-0">
                                {{ this.LANG_ARR.memes[3].comment_4_text_9 }}
                            </div>
                        </div>
                        <div class="comment-text">
                            {{ this.LANG_ARR.memes[3].comment_4_text_6 }}
                        </div>
                    </div>
                    <div class="button" v-if="full_comment !== 4">
                        <div class="cool_btn" @click="this.full_comment = 4">
                            {{ this.LANG_ARR.memes[1].show_more }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "services",
    computed: {
        ...mapGetters(["LANGUAGE", "LANG_ARR"]),
    },
    data() {
        return {
            is_show_more_1: false,
            is_show_more_2: false,
            is_show_more_3: false,
            is_show_more_4: false,
            is_show_more_5: false,
            full_comment: 0,
            meme_popup: false,
            active_meme: {
                /*                 idx: 0,
                                data: '',
                                img: "",
                                date: "",
                                history: "" */
            },
            memes_1: [
                {
                    idx: 0,
                    data: "memes_1",
                    img: "feb-1.jpg",
                    date: "перша половина лютого 2022 року",
                    history: "Розвідки багатьох країн у лютому прогнозували вторгнення росії в Україну. Єдине що називали різні дати. Звучали й 14, і 16, і 22 лютого. У відповідь на це соцмережі вибухнули мемами із загальною темою “В очікуванні вторгнення”.",
                },
                {
                    idx: 1,
                    data: "memes_1",
                    img: "feb-2.jpg",
                    date: "перша половина лютого 2022 року",
                    history: "Розвідки багатьох країн у лютому прогнозували вторгнення росії в Україну. Причиною були погодні умови: по мерзлих ґрунтах і водоймах легше було б рухатися вглиб країни. Однак, в озвучені дати (14, 16 чи 22 лютого) вторгнення не відбувалося, тому в соцмережах почали жартувати про те, що вже б “швидше” або “якось визначитися”.",
                },
                {
                    idx: 2,
                    data: "memes_1",
                    img: "feb-3.jpg",
                    date: "перша половина лютого 2022 року",
                    history: "Про “очікування вторгнення” жартував навіть англомовний сегмент соцмереж. Зокрема, й Twitter. Конкретно на цьому мемі додали ще й Javelin, бо напередодні США та Велика Британія завезла в Україну свої Javelin, NLAW та інше оснащення.",
                },
                {
                    idx: 3,
                    data: "memes_1",
                    img: "feb-4.jpg",
                    date: "середина лютого 2022 року",
                    history: "Оскільки в інформаційному полі регулярно була інформація про вторгнення росії, з’явилося багато мемів про те, хто залишиться і хто битиметься. Власники котів/собак писали, що з тваринами до укриттів не пускають, тож вони будуть “драцца”. Про те ж саме жартували й фермери – як на цьому зображенні.",
                },
                {
                    idx: 4,
                    data: "memes_1",
                    img: "feb-5.jpg",
                    date: "перші дні повномасштабної війни",
                    history: "В Україні діє адміністративна відповідальність за знищення рослин з Червоної книги України: або штраф, або повністю відшкодування завданої шкоди довкіллю. Тож українці не ризикують викопувати червонокнижні культури. Особливо з таким “добривом”."
                },
                /*                 {
                                    idx: 5,
                                    data: "memes_1",
                                    img: "feb-6.jpg",
                                    date: "перші дні повномасштабної війни",
                                    history: ""
                                }, */
                {
                    idx: 5,
                    data: "memes_1",
                    img: "feb-7.jpg",
                    date: "перші дні повномасштабної війни",
                    history: "У перші дні повномасштабної війни почали з’являтися кадри з Сумської, Чернігівської, Харківської областей, де фермери забирали в російських солдат танки, БТРи, зброю, інший транспорт. Це стало основою для справжньої лавини з мемів, на яких висміюється недолугість “втарой арміі міра”, яка не може вберегти власну техніку від беззахисного тракториста."
                },
                {
                    idx: 6,
                    data: "memes_1",
                    video: "feb-8.mp4",
                    date: "перші дні повномасштабної війни",
                    history: ""
                },
                {
                    idx: 7,
                    data: "memes_1",
                    video: "feb-9.mp4",
                    date: "перші дні повномасштабної війни",
                    history: ""
                },
            ],
            memes_2: [
                {
                    idx: 0,
                    data: "memes_2",
                    img: "march-1.jpg",
                    date: "березень 2022 року",
                    history: "Коли весь світ зрозумів, які круті українські фермери, то з’явилися меми, з яких одразу стає зрозуміло, хто насправді рухає наш світ, запускає ракети в космос і освоює Марс.",
                },
                {
                    idx: 1,
                    data: "memes_2",
                    img: "march-2.jpg",
                    date: "початок березня 2022 року",
                    history: "Тут із самого мема все зрозуміло: м*ск*ль може згодитися хіба на біогумус. І то пізніше біологи сказали, що не дуже й згодиться, але потік мемів уже було не спинити."
                },
                {
                    idx: 2,
                    data: "memes_2",
                    img: "march-3.jpg",
                    date: "початок березня 2022 року",
                    history: "Дівчина захищає своє – що тут ще незрозуміло. Бо українці, коли воюють, то повстають уже геть усі 💪"
                },
                {
                    idx: 3,
                    data: "memes_2",
                    img: "march-4.jpg",
                    date: "початок березня 2022 року",
                    history: "Українці – нація, яка любить землю. Ми садимо гектари пшениці, соняшника й кукурудзи, але завжди лишаємо трішки… під буряки. Бо – то є любов ❤️"
                },
                {
                    idx: 4,
                    data: "memes_2",
                    img: "march-5.jpg",
                    date: "початок березня 2022 року",
                    history: "Власне, цитата жіночки вже все описала й пояснила."
                },
                {
                    idx: 5,
                    data: "memes_2",
                    img: "march-6.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 6,
                    data: "memes_2",
                    img: "march-7.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 7,
                    data: "memes_2",
                    img: "march-8.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 8,
                    data: "memes_2",
                    img: "march-9.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 9,
                    data: "memes_2",
                    img: "march-10.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 10,
                    data: "memes_2",
                    img: "march-11.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 11,
                    data: "memes_2",
                    img: "march-12.jpg",
                    date: "початок березня 2022 року",
                    history: "Тут білий песик – метафора на “занепокоєність” низки іноземних інституцій, які, за логікою, мали б реагувати трохи по-іншому. А от до списку із ЗСУ, ТрО, волонтерів і трактористів додалися ще й цигани, бо в перші дні повномасштабної війни були реальні випадки, коли вони крали російську техніку. "
                },
                {
                    idx: 12,
                    data: "memes_2",
                    img: "march-13.jpg",
                    date: "початок березня 2022 року",
                    history: "У березні в південних областях попри війну-чуму-карантин таки стартувала посівна."
                },
                {
                    idx: 13,
                    data: "memes_2",
                    img: "march-14.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 14,
                    data: "memes_2",
                    img: "march-15.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 15,
                    data: "memes_2",
                    img: "march-16.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 16,
                    data: "memes_2",
                    img: "march-17.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 17,
                    data: "memes_2",
                    img: "march-18.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 19,
                    data: "memes_2",
                    img: "march-19.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 20,
                    data: "memes_2",
                    img: "march-21.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 21,
                    data: "memes_2",
                    img: "march-22.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 22,
                    data: "memes_2",
                    img: "march-23.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 23,
                    data: "memes_2",
                    img: "march-24.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 24,
                    data: "memes_2",
                    img: "march-25.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 25,
                    data: "memes_2",
                    img: "march-26.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 26,
                    data: "memes_2",
                    img: "march-27.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 27,
                    data: "memes_2",
                    img: "march-28.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 28,
                    data: "memes_2",
                    img: "march-29.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 29,
                    data: "memes_2",
                    img: "march-30.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 30,
                    data: "memes_2",
                    img: "march-31.jpg",
                    date: "початок березня 2022 року",
                    history: "Вони не просто ходили по копаному – вони ходили по озимині, яка вже зійшла 🤬"
                },
                {
                    idx: 31,
                    data: "memes_2",
                    img: "march-32.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 32,
                    data: "memes_2",
                    img: "march-33.jpg",
                    date: "початок березня 2022 року",
                    history: "Шеврон зобразив ілюстратор і дизайнер Олександр Грехов."
                },
                {
                    idx: 33,
                    data: "memes_2",
                    img: "march-34.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 34,
                    data: "memes_2",
                    img: "march-35.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 35,
                    data: "memes_2",
                    img: "march-36.jpg",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 36,
                    data: "memes_2",
                    video: "march-37.mp4",
                    date: "початок березня 2022 року",
                    history: ""
                },
                {
                    idx: 37,
                    data: "memes_2",
                    video: "march-38.mp4",
                    date: "початок березня 2022 року",
                    history: ""
                }
            ],
            memes_3: [
                {
                    idx: 0,
                    data: "memes_3",
                    img: "april-0.jpg",
                    date: "квітень 2022 року",
                    history: ""
                },

                {
                    idx: 1,
                    data: "memes_3",
                    img: "april-1.jpg",
                    date: "квітень 2022 року",
                    history: ""
                },

                {
                    idx: 2,
                    data: "memes_3",
                    img: "april-2.jpg",
                    date: "квітень 2022 року",
                    history: "Коли деякі області звільнили від окупантів, то після них позалишалися ось такі “подарунки”. Винахідливі українці для деяких з них одразу вигадали застосування."
                },

                {
                    idx: 3,
                    data: "memes_3",
                    img: "april-3.jpg",
                    date: "квітень 2022 року",
                    history: "Під час посівної в деяких областях була проблема – не вистачало палива, добрив, а десь р*сня покрала техніку. Мемний батальйон не лишив аграріїв без моральної підтримки."
                },

                {
                    idx: 4,
                    data: "memes_3",
                    img: "april-4.jpg",
                    date: "квітень 2022 року",
                    history: "13 квітня ВМС України випустили по флагману Чорноморського флоту ВМФР ракетному крейсеру “Москва” 2 ракети “Нептун”. “Москва” остаточно пішла давати цицьки жабам 14 квітня. Мемний десант не забарився з відповіддю."
                },

                {
                    idx: 5,
                    data: "memes_3",
                    img: "april-5.jpg",
                    date: "квітень 2022 року",
                    history: ""
                },

                {
                    idx: 6,
                    data: "memes_3",
                    img: "april-6.jpg",
                    date: "квітень 2022 року",
                    history: ""
                },

                {
                    idx: 7,
                    data: "memes_3",
                    img: "april-7.jpg",
                    date: "квітень 2022 року",
                    history: ""
                },

                {
                    idx: 8,
                    data: "memes_3",
                    img: "april-8.jpg",
                    date: "квітень 2022 року",
                    history: ""
                },

                {
                    idx: 9,
                    data: "memes_3",
                    img: "april-9.jpg",
                    date: "квітень 2022 року",
                    history: "24 квітня православні українці відзначали Великдень. Яке б це було свято без мемів?"
                },

                {
                    idx: 10,
                    data: "memes_3",
                    img: "april-10.jpg",
                    date: "квітень 2022 року",
                    history: ""
                },

                {
                    idx: 11,
                    data: "memes_3",
                    img: "april-11.jpg",
                    date: "квітень 2022 року",
                    history: ""
                },
                {
                    idx: 12,
                    data: "memes_3",
                    img: "april-12.jpg",
                    date: "квітень 2022 року",
                    history: ""
                },
            ],
            memes_4: [
                {
                    idx: 0,
                    data: "memes_4",
                    img: "may-0.jpg",
                    date: "травень 2022 року",
                    history: "Щороку в травні багато українців організовують тімбілдинг – разом сапають город. Воєнний рік не став винятком, бо ракети ракетами, а бур’ян на городі сам себе не виполе."
                },

                {
                    idx: 1,
                    data: "memes_4",
                    img: "june-0.jpg",
                    date: "літо 2022 року",
                    history: ""
                },

                {
                    idx: 2,
                    data: "memes_4",
                    img: "june-1.jpg",
                    date: "літо 2022 року",
                    history: "Мемчик про те “Як німці давали зброю Україні” і приказували при цьому “Йой, а хтось нею вміє користуватися? А якщо ви нею кудись не туди стрельнете? А якщо ця зброя потім потрапить до росіян? Йой, що то буде”. А потім усі заговорили, що Європі й Африці треба українське зерно. Бо ціни високі, продовольча криза там і т.д. Отримали відповідь."
                },
                {
                    idx: 3,
                    data: "memes_4",
                    img: "june-2.jpg",
                    date: "літо 2022 року",
                    history: ""
                },
                {
                    idx: 4,
                    data: "memes_4",
                    img: "june-3.jpg",
                    date: "літо 2022 року",
                    history: ""
                },
            ],
            memes_5: [
                {
                    idx: 0,
                    data: "memes_5",
                    img: "july-0.jpg",
                    date: "літо 2022 року",
                    history: ""
                },
                {
                    idx: 1,
                    data: "memes_5",
                    img: "july-1.jpg",
                    date: "літо 2022 року",
                    history: "У кінці червня зі США прибули перші високомобільні артилерійські системи ракетного вогню HIMARS. Вони роблять “бавовну” по всій лінії фронту і ще й у Криму. Цей вид зброї окремо “оспівали” в своїх мемах українці."
                },
                {
                    idx: 2,
                    data: "memes_5",
                    img: "july-2.jpg",
                    date: "літо 2022 року",
                    history: "Коли ми просили зброю, хтось давав, а хтось підсвічував будівлі в кольори України. Коли заговорили про зерно, мемні війська відреагували так само."
                },
                {
                    idx: 3,
                    data: "memes_5",
                    img: "july-3.jpg",
                    date: "літо 2022 року",
                    history: "Кавуни з Херсонщини – то українська гордість і насолода для кожного українця. Хто не об’їдався хоч раз у житті кавуном – вважай не жив. Коли в кінці липня почав підходити сезон кавунів, пішли й меми про їхню доставку на підконтрольну Україні територію, бо Херсонщина поки в лапах окупантів."
                },
                {
                    idx: 4,
                    data: "memes_5",
                    img: "july-4.jpg",
                    date: "літо 2022 року",
                    history: ""
                },
                {
                    idx: 5,
                    data: "memes_5",
                    img: "july-5.jpg",
                    date: "літо 2022 року",
                    history: ""
                },
                {
                    idx: 6,
                    data: "memes_5",
                    img: "july-6.jpg",
                    date: "літо 2022 року",
                    history: ""
                },
                {
                    idx: 7,
                    data: "memes_5",
                    img: "august-0.jpg",
                    date: "літо 2022 року",
                    history: "Насіння в кишенях – давній звичай російських загарбників: коли вони їдуть кудись подихАти, то за повір’ям мають покласти в кишені насіннячко, щоб воно проросло і з тої біомаси була хоч якась користь. Насіннячко їм радила класти в кишені навіть старенька бабуся в одному з окупованих сіл на початку березня. Напевно, хтось таки дослухався до слушної поради."
                },
                {
                    idx: 8,
                    data: "memes_5",
                    img: "august-1.jpg",
                    date: "літо 2022 року",
                    history: "володимир сальдо – колишній мер Херсона, а нині – колаборант і зрадник. На початку серпня він написав, що захворів, потім, що його госпіталізували, його він комусь передав обов’язки, а потім… Та ми не надто стежимо за його здоров’ям, але меми маємо."
                },
                {
                    idx: 9,
                    data: "memes_5",
                    img: "august-2.jpg",
                    date: "літо 2022 року",
                    history: ""
                },
            ]
        }
    },
    methods: {
        open_meme(item) {
            this.active_meme = item
            this.meme_popup = true
        },
        next_meme(parameter) {
            let meme_data = this.active_meme.data
            let new_meme_idx;
            if (parameter == "next") {
                if (this.active_meme.idx + 1 < this[meme_data].length) {
                    new_meme_idx = this.active_meme.idx + 1
                    this.active_meme = this[meme_data][new_meme_idx]
                } else {
                    this.active_meme = this[meme_data][0]
                }
            } else {
                new_meme_idx = this.active_meme.idx - 1
                if (new_meme_idx > -1) {
                    this.active_meme = this[meme_data][new_meme_idx]
                } else {
                    this.active_meme = this[meme_data][this[meme_data].length - 1]
                }
            }

        }
    }
}
</script>

<style lang="scss" scoped>
.comment {
    border: 3px solid #000000;
    padding: 50px;
    margin: 25px 0;

    .comment-title {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        color: #000000;
        padding: 30px 0;
    }

    .comment-subtitle {
        padding: 30px 0;
        font-style: italic;
        font-weight: 500;
        font-size: 32px;
        line-height: 39px;
        color: #000000;
    }

    .title {
        font-style: italic;
        font-weight: 700;
        font-size: 60px;
        line-height: 73px;
        color: #54791A;
    }

    .description {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        .name {
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 49px;
            text-align: right;
            color: #FF6B00;
        }

        .prof {
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 39px;
            text-align: right;
            color: #000000;
        }
    }

    .comment-text {
        margin-top: 30px;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 39px;
        color: #000000;
    }

    .comment-ul {
        font-style: italic;
        font-weight: 400;
        font-size: 32px;
        line-height: 39px;
        margin-top: 20px;
    }
}

#memes {
    position: relative;

    .meme-popup {
        width: 100%;
        height: 100vh;
        position: fixed;
        z-index: 22;
        /* top: -140px; */
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(10px);

        .fa-times {
            position: absolute;
            top: 10%;
            right: 10%;
            font-size: 50px;
            color: #fff;
            cursor: pointer;
            opacity: 0.5;
            transition-duration: .5s;

            &:hover {
                opacity: 1;
            }
        }

        .fa-chevron-right {
            font-size: 50px;
            cursor: pointer;
            margin-left: 10%;
            color: #fff;
            opacity: 0.5;
            transition-duration: .5s;

            &:hover {
                opacity: 1;
            }
        }

        .fa-chevron-left {
            font-size: 50px;
            cursor: pointer;
            margin-right: 10%;
            color: #fff;
            opacity: 0.5;
            transition-duration: .5s;

            &:hover {
                opacity: 1;
            }
        }

        .meme-popup-container {
            position: relative;
            width: 800px;
            height: max-content;
            background: #201e1c;

            .texture {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                background-image: url("../assets/images/Dirty-Surface-Texture-03.png");
                opacity: 0.7;
            }

            .content {
                padding: 50px;
                position: relative;
                z-index: 99;

                .date {
                    border: 3px solid #FFFFFF;
                    padding: 15px;

                    .date-title {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 29px;
                        color: #FFFFFF;
                        margin-right: 15px;
                    }

                    .date-value {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 24px;
                        color: #FFFFFF;
                    }
                }

                .history {
                    margin-top: 25px;

                    .history-title {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 29px;
                        color: #FFCD1C;
                        margin-bottom: 15px;
                    }

                    .history-description {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        color: #FFFFFF;
                    }
                }

                img,
                video {
                    position: relative;
                    z-index: 99;
                    width: 100%
                }
            }
        }
    }
}

.black-container {
    background: #201e1c;
    position: relative;
    padding: 60px 0;
    overflow: hidden;

    .bg-sun {
        position: absolute;
        top: -54%;
        left: 46%;
        width: 35%;
    }

    .texture {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url("../assets/images/Dirty-Surface-Texture-03.png");
        opacity: 0.7;
    }

    .content {
        font-style: italic;
        font-weight: 700;
        font-size: 96px;
        line-height: 117px;
        text-align: center;
        color: #FFFFFF;
        position: relative;
        z-index: 2;
    }
}

.green-container {
    background: #4e7701;
    padding: 30px 0;
    position: relative;

    .texture {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background-image: url("../assets/images/Dirty-Surface-Texture-03.png");
        opacity: 1;
    }

    .content {
        font-style: italic;
        font-weight: 700;
        font-size: 64px;
        line-height: 78px;
        text-align: center;
        color: #FFFFFF;
    }
}

.memes-content {
    padding: 80px 0;

    .text-block {
        border: 3px solid #54791A;
        padding: 45px;

        .title {
            font-style: italic;
            font-weight: 700;
            font-size: 30px;
            line-height: 34px;
            color: #54791A;
            margin-bottom: 25px;
        }

        .description {
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 34px;
            color: #000000;

            .subdesc {
                margin-top: 25px;
                font-style: italic;
                font-weight: 500;
                font-size: 20px;
                line-height: 25px;
            }

            .subul {
                margin-top: 25px;
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                color: #000000;
            }
        }
    }
}

.slider-container {
    padding: 120px 2%;

    .date {
        color: #54791A;
        font-style: italic;
        font-weight: 700;
        font-size: 58px;
        line-height: 71px;
        text-transform: uppercase;
        color: #000000;
        padding-right: 50px;

        .subtitle {
            color: #FF6B00;
        }
    }

    .memes-slider {
        padding: 0 20px;

        .preloaded {
            height: 467px;
            overflow: hidden;

            &.active {
                height: max-content;
            }
        }

        .meme-item {
            margin-bottom: 35px;
            cursor: pointer;

            img,
            video {
                width: 200px;
                height: 200px;
            }

        }
    }

}

.button {
    width: 100%;
    display: flex;
    justify-content: center;
}

.cool_btn {
    position: relative;
    padding: 10px 30px;
    color: #54791A;
    border: transparent;
    text-decoration: none;
    font-size: 20px;
    text-transform: uppercase;
    transition: 0.5s;
    background: transparent;
    cursor: pointer;

    &::before {
        content: " ";
        position: absolute;
        top: -1px;
        left: -1px;
        width: 10px;
        height: 10px;
        border-top: 2px solid #54791A;
        border-left: 2px solid #54791A;
        transition: 0.5s;
        transition-delay: 0.5s;
    }

    &::after {
        content: " ";
        position: absolute;
        bottom: -1px;
        right: -1px;
        width: 10px;
        height: 10px;
        border-bottom: 2px solid #54791A;
        border-right: 2px solid #54791A;
        transition: 0.5s;
        transition-delay: 0.5s;
    }

    &:hover::before {
        width: 100%;
        height: 100%;
        transition-delay: 0s;
    }

    &:hover::after {
        width: 100%;
        height: 100%;
        transition-delay: 0s;
    }

    &:hover {
        background: #54791A;
        color: #fff;
        /* box-shadow: 0 0 50px #54791A; */
        transition-delay: 0.5s;
    }
}

@media screen and (max-width: 1300px) {
    .slider-container .memes-slider .meme-item img, .slider-container .memes-slider .meme-item video {
        width: 150px;
        height: 150px;
    }
}

@media screen and (max-width: 600px) {
    .black-container {
        padding: 30px;

        .content {
            font-size: 26px;
            line-height: 30px;
        }
    }

    .green-container {
        padding: 30px;

        .content {
            font-size: 26px;
            line-height: 30px;
        }
    }

    .memes-content {
        .text-block {
            padding: 15px;

            .title {
                font-size: 20px;
                line-height: 24px;
            }

            .description {
                font-size: 18px;
                line-height: 22px;

                .subul {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
    }

    .slider-container {
        padding: 60px 3% 0;

        .date {
            border: none !important;

            .subtitle {
                padding-bottom: 25px;
            }
        }

        .memes-slider {
            padding: 0;

            .meme-item {
                img {
                    width: 167px;
                    height: 167px;
                }
            }

            .preloaded {
                height: 380px;
            }
        }

    }

    #memes .meme-popup .fa-times {
        font-size: 20px;
        right: 12%;
        top: 13%;
    }

    #memes .meme-popup .fa-chevron-right {
        position: absolute;
        right: 10px;
        z-index: 100;
    }

    #memes .meme-popup .fa-chevron-left {
        position: absolute;
        left: 10px;
        z-index: 100;
    }

    #memes .meme-popup .meme-popup-container {
        margin: 0 20px;
    }

    #memes .meme-popup .meme-popup-container {
        max-height: 80vh;
        overflow-y: scroll;
    }

    #memes .meme-popup .meme-popup-container .content .date .date-value {
        font-size: 14px;
        line-height: 18px;
    }

    #memes .meme-popup .meme-popup-container .content .date .date-title {
        font-size: 14px;
        line-height: 28px;
    }

    .comment {
        border: 3px solid #000000;
        padding: 20px;
        margin: 25px 0;

        .comment-title {
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 19px;
            color: #000000;
            padding: 30px 0;
        }

        .comment-subtitle {
            padding: 30px 0;
            font-style: italic;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: #000000;
        }

        .title {
            font-style: italic;
            font-weight: 700;
            font-size: 20px;
            line-height: 23px;
            color: #54791A;
        }

        .description {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;

            img {
                width: 25px !important;
            }

            .name {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 19px;
                text-align: right;
                color: #FF6B00;
            }

            .prof {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                text-align: right;
                color: #000000;
            }
        }

        .comment-text {
            margin-top: 30px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 19px;
            color: #000000;
        }

        .comment-ul {
            font-style: italic;
            font-weight: 400;
            font-size: 12px;
            line-height: 19px;
            margin-top: 20px;
        }

        .button {
            margin-top: 20px;
        }
    }
}
</style>