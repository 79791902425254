<template>
  <div id="footer">
    <div class="texture"></div>
    <div class="container">
      <div class="logo">
        <router-link class="nav-item" to="/"
          ><img :src="require('../assets/images/footer-logo.png')" alt=""
        /></router-link>
      </div>
      <div class="navbar">
        <router-link
          v-for="item in this.LANG_ARR.wrapper"
          :key="item.idx"
          class="nav-item"
          @click="change_page(item.link, item.id)"
          :to="item.link"
          >{{ item.name }}</router-link
        >
      </div>
      <div class="social">
        <i @click="linkTo('https://www.facebook.com/barbaro.communications')" class="fab fa-facebook"></i>
        <i @click="linkTo('https://www.instagram.com/barbaro.communications/')" class="fab fa-instagram"></i>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "footer",
  data() {
    return {
    };
  },
  methods: {
    ...mapActions(["SCROLL"]),
    change_page(item, id) {
      this.SCROLL(id);

      if (this.GO_TO != "other_page" && this.GO_TO != "home") {
        setTimeout(() => this.scrollTo(id), 100);
      } else if (this.GO_TO = "other_page") {
        window.scrollTo(0, 0);
      }
    },
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
    linkTo(url) {
      window.location = url
    }
  },
  computed: {
    ...mapGetters(["GO_TO", "LANG_ARR"]),
  },
};
</script>

<style lang="scss" scoped>
#footer {
  /*   margin: 150px 0 0; */
  background: #4e7701;
  padding: 100px 0;
  position: relative;
  .texture {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: url("../assets/images/Dirty-Surface-Texture-03.png");
    opacity: 1;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 20%;
  cursor: pointer;
}
.navbar {
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  .nav-item {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    opacity: 0.8;
    text-decoration: none;
    &:hover {
      color: rgba(32, 30, 28, 0.35);
    }
  }
}

.social {
  width: 20%;
  text-align: end;
  position: relative;
  z-index: 90;
  i {
    margin: 0 8px;
    font-size: 25px;
    cursor: pointer;
    &:hover {
      color: rgba(32, 30, 28, 0.35);
    }
  }
}

@media screen and (max-width: 1215px) {
  .navbar {
    width: 75%;
  }
  .social {
    width: 15%;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
  }

  .navbar {
    width: 100%;
    .nav-item {
      margin: 2px 10px;
      font-size: 12px;
    }
  }
  .social {
    width: 100%;
    text-align: center;
  }
  .logo {
    margin-top: 20px;
    width: 100%;
    cursor: pointer;
    text-align: center;
    img {
      margin-left: 4px;
    }
  }

  #footer {
    /*   margin: 150px 0 0; */
    background: #4e7701;
    padding: 40px 0;
  }
}
</style>