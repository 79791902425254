<template>
  <div id="slider">
    <div class="container">
      <div class="title">{{this.LANG_ARR.slider[0].title}}</div>
      <!--       <div class="slider">
        <div class="slider-container">
          <div class="slider-track">
            <img :src="require('../assets/images/slider-track.png')" alt="" />
          </div>
        </div>
      </div> -->
      <carousel id="clients" :settings="settings" :breakpoints="breakpoints">
        <slide v-for="slide in clients" :key="slide">
          <div class="carousel__item">
            <img :src="require(`../assets/images/${slide.img}`)" alt="" />
          </div>
        </slide>
        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
    </div>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { mapGetters } from "vuex";

export default {
  name: "slider",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      clients: [
        {
          img: "slide-1.png",
        },
        {
          img: "slide-2.png",
        },
        {
          img: "slide-3.png",
        },
        {
          img: "slide-4.png",
        },
        {
          img: "slide-5.png",
        },
        {
          img: "slide-6.png",
        },
        {
          img: "slide-7.png",
        },
        {
          img: "slide-8.png",
        },
        {
          img: "slide-9.png",
        },
        {
          img: "slide-10.png",
        },
      ],
      settings: {
        itemsToShow: 3.7,
        wrapAround: true,
        autoplay: "5000",
      },
      breakpoints: {
        1400: {
          itemsToShow: 3.7,
          wrapAround: true,
        },
        1200: {
          itemsToShow: 3,
          wrapAround: true,
        },
        800: {
          itemsToShow: 2.5,
          wrapAround: true,
        },
        375: {
          itemsToShow: 2,
          wrapAround: true,
        },
      },
    };
  },
  /*   mounted() {
    this.scrollSlide();
  }, */
  methods: {},
    computed: {
    ...mapGetters(["LANG_ARR"]),
  },
};
</script>

<style lang="scss" scoped>
#slider {
  margin: 80px 0;
}

.title {
  font-style: italic;
  font-weight: bold;
  font-size: 80px;
  line-height: 143.9%;
  margin-bottom: 50px;
}

.container {
  padding: 100px 80px !important;
}

/* .slider {
  .slider-container {
    margin-top: 50px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 222px;
    .slider-track {
      position: absolute;
      top: 0;
      left: 0;
      animation: scroll-slide 30s linear infinite;
      &:hover {
        animation-play-state: paused;
      }
    }
  }
} */

/* @keyframes scroll-slide {
  0% {
    position: absolute;
    top: 0;
    left: 0;
  }
  50% {
    left: -2000px;
  }
  100% {
    left: 40px;
  }
} */

@media screen and (max-width: 1215px) {
  /*   #slider {
    margin: 40px 0;
  } */
  .container {
    padding: 60px 40px !important;
  }
}

@media screen and (max-width: 1000px) {
  .title {
    font-size: 40px;
    text-align: center;
  }
  #slider {
    margin: 40px 0;
  }
  .container {
    padding: 0 30px !important;
  }
  /*   .slider {
    .slider-container {
      margin-top: 50px;
      overflow: hidden;
      position: relative;
      width: 100%;
      .slider-track {
        position: absolute;
        top: 0;
        left: 0;
        animation: scroll-slide 20s linear infinite;
        img{
          width: 100%;
        }
      }
    }
  } */
}
</style>

<style>
.carousel__viewport {
  padding: 20px 0;
}

@media screen and (max-width: 1000px) {
  .carousel__item {
    width: 120px;
    height: 100px;
    border: 2px solid rgba(32, 30, 28, 0.5);
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .carousel__item img {
    width: 50%;
  }
}

@media screen and (min-width: 1010px) {
  .carousel__item {
    width: 282px;
    height: 222px;
    border: 2px solid rgba(32, 30, 28, 0.5);
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .carousel__slide > .carousel__item {
    transform: scale(1);
    opacity: 0.3;
    transition: 0.5s;
  }
  .carousel__slide--visible > .carousel__item {
    opacity: 1;
    transform: rotateY(0);
  }
  .carousel__slide--next > .carousel__item {
    transform: scale(0.9);
  }
  .carousel__slide--prev > .carousel__item {
    transform: scale(0.9);
  }
  .carousel__slide--active > .carousel__item {
    transform: scale(1.1);
  }
}
</style>