<template>
  <Wrapper class="wrapper" />
  <router-view />
  <Footer />
</template>

<script>
import Wrapper from "@/components/wrapper.vue";
import Footer from "@/components/footer-component.vue";
export default {
  components: {
    Wrapper,
    Footer,
  },
};
</script>

<style lang="scss">
#app {
  background-image: url("./assets/BARBARO60.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  overflow: hidden;
  font-family: "Montserrat", sans-serif !important;
  position: relative;
}

/* .texture {
  position: absolute;
  top: 0;
  z-index: 4;
  width: 100%;
  img {
    width: 100%;
  }
} */

.wrapper {
  position: relative;
  z-index: 5;
}

.container {
  max-width: 1320px;
  padding: 0 80px !important;
}

@media screen and (max-width: 1400px) {
  .container {
    padding: 0 20px !important;
  }
}
</style>
