<template>
  <div id="mission">
    <div class="main-content">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6 image">
            <img
              class="ellipse"
              :src="require('../assets/images/mission-ellipse.png')"
              alt=""
            />
            <img
              class="mission-pr"
              :src="require('../assets/images/mission-pr.png')"
              alt=""
            />
          </div>
          <div class="col-12 col-lg-6">
            <div class="title">{{ this.LANG_ARR.mission[0].title }}</div>
            <div class="subtitle">{{ this.LANG_ARR.mission[0].subtitle }}</div>
            <div class="points">
              <div class="point">
                <div class="number">1</div>
                <div :class="{ print_text: print_desc }">
                  {{ this.LANG_ARR.mission[0].text1 }}
                </div>
              </div>
              <div class="point">
                <div class="number">2</div>
                <div :class="{ print_text: print_desc }">
                  {{ this.LANG_ARR.mission[0].text2 }}
                </div>
              </div>
              <div class="point">
                <div class="number">3</div>
                <div :class="{ print_text: print_desc }">
                  {{ this.LANG_ARR.mission[0].text3 }}
                </div>
              </div>
              <div class="point">
                <div class="number">4</div>
                <div :class="{ print_text: print_desc }">
                  {{ this.LANG_ARR.mission[0].text4 }}
                </div>
              </div>
            </div>
            <div class="description">
              {{ this.LANG_ARR.mission[0].description1 }}
              <br />
              <br />
              {{ this.LANG_ARR.mission[0].description2 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "mission",
  data() {
    return {
      print_desc: false,
      print_mob: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.offset);
  },
  methods: {
    offset() {
      if (pageYOffset >= 1200) {
        this.print_desc = true;
      } else {
        this.print_desc = false;
      }
    },
  },
  computed: {
    ...mapGetters(["LANG_ARR"]),
  },
};
</script>

<style lang="scss" scoped>
#mission {
  background: #201e1c;
  padding: 100px 0;
}

.image {
  position: relative;
  .ellipse {
    position: absolute;
    top: 0;
    width: 100%;
  }
  .mission-pr {
    position: relative;
    z-index: 2;
    width: 80%;
  }
}

.title {
  font-style: italic;
  font-weight: bold;
  font-size: 70px;
  color: #fff;
  margin: 0 0 20px;
}

.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  margin: 30px 0;
}

.points {
  .point {
    display: flex;
    color: #ffcd1c;
    align-items: center;
    /* padding: 5px 0; */
    border-top: 1px solid #ffcd1c;
    font-size: 18px;
    /* border-bottom: 1px solid #ffcd1c; */
    &:nth-child(4) {
      border-bottom: 1px solid #ffcd1c;
    }
    .number {
      font-style: italic;
      font-weight: bold;
      font-size: 30px;
      width: 10%;
      margin-right: 20px;
    }
  }
}

.description {
  margin-top: 20px;
  color: #fff;
  font-size: 16px;
}

.print_text {
  width: 50ch;
  font-family: monospace;
  white-space: nowrap;
  overflow: hidden;
  animation: printed-text 5s steps(50);
}

@keyframes printed-text {
  from {
    width: 0;
  }
}

@media screen and (max-width: 1000px) {
  #mission {
    padding: 60px 0;
  }

  .image {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    .ellipse {
      width: 70%;
    }
    .mission-pr {
      position: relative;
      z-index: 2;
      width: 60%;
    }
  }

  .points {
    .point {
      font-size: 12px;
      .number {
        font-size: 18px;
        width: 6%;
      }
    }
  }
  .title {
    font-size: 35px;
    text-align: center;
  }

  .subtitle {
    text-align: center;
    font-size: 20px;
  }

  .description {
    font-size: 14px;
  }
}
</style>