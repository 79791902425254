<template>

    <div id="services">
        <div class="img-title">
            <img :src="require('../assets/images/who-are-left-title.png')" alt="" />
            <img :src="require(`../assets/images/servises_title_${this.LANG_ARR.services[0].image}.png`)" alt="" />
            <img :src="require('../assets/images/who-are-right-title.png')" alt="" />
        </div>
        <div class="main-description pb-5" v-html="this.LANG_ARR.services[0].main_description">
        </div>
        <div class="container mt-5">
            <div class="service-container" id="scroll_1">
                <div class="title">
                    <div class="number">
                        01
                    </div>
                    {{ this.LANG_ARR.services[0].title_1 }}
                </div>
                <div class="section_1">
                    <div class="section-item">
                        <div class="image">
                            <img style="width: 45px;" :src="require('../assets/images/yellow-star.png')" alt="" />
                        </div>
                        <div class="text mt-2">
                            {{ this.LANG_ARR.services[0].text_1 }}
                            <div class="subtext">
                                {{ this.LANG_ARR.services[0].subtext_1 }}
                            </div>
                        </div>
                    </div>
                    <div class="section-item">
                        <div class="image">
                            <img style="width: 45px;" :src="require('../assets/images/yellow-star.png')" alt="" />
                        </div>
                        <div class="text mt-2">
                            {{ this.LANG_ARR.services[0].text_2 }}
                        </div>
                    </div>
                    <div class="section-item">
                        <div class="image">
                            <img style="width: 45px;" :src="require('../assets/images/yellow-star.png')" alt="" />
                        </div>
                        <div class="text mt-2">
                            {{ this.LANG_ARR.services[0].text_3 }}
                        </div>
                    </div>
                    <div class="section-item">
                        <div class="image">
                            <img style="width: 45px;" :src="require('../assets/images/yellow-star.png')" alt="" />
                        </div>
                        <div class="text mt-2">
                            {{ this.LANG_ARR.services[0].text_4 }}
                        </div>
                    </div>
                </div>
                <div class="button">
                    <router-link class="cool_btn" @click="change_page('other_page', 4000)" to="/">
                        {{ this.LANG_ARR.services[0].button_1 }}
                    </router-link>
                </div>
            </div>
            <div class="service-container" id="scroll_2">
                <div class="title">
                    <div class="number">
                        02
                    </div>
                    {{ this.LANG_ARR.services[0].title_2 }}
                </div>
                <div class="section_2">
                    <div class="section-item">
                        <img style="width: 65px;" class="mb-2 mb-md-4"
                            :src="require('../assets/images/yellow-star.png')" alt="" />
                        <div class="text mb-2">
                            {{ this.LANG_ARR.services[0].text_5 }}
                        </div>
                        <div class="subtext">
                            {{ this.LANG_ARR.services[0].subtext_2 }}
                        </div>
                    </div>
                    <div class="section-item">
                        <img style="width: 65px;" class="mb-2 mb-md-4"
                            :src="require('../assets/images/yellow-star.png')" alt="" />
                        <div class="text mb-2">
                            {{ this.LANG_ARR.services[0].text_51 }}
                        </div>
                        <div class="subtext">
                            {{ this.LANG_ARR.services[0].subtext_2 }}
                        </div>
                    </div>
                </div>
                <div class="button">
                    <router-link class="cool_btn" @click="change_page('other_page', 4000)" to="/">
                        {{ this.LANG_ARR.services[0].button_2 }}
                    </router-link>
                </div>
            </div>
            <div class="service-container" id="scroll_3">
                <div class="title">
                    <div class="number px-md-4">
                        03
                    </div>
                    {{ this.LANG_ARR.services[0].title_3 }}
                </div>
                <div class="section_1">
                    <div class="section-item">
                        <div class="image">
                            <img style="width: 45px;" :src="require('../assets/images/yellow-star.png')" alt="" />
                        </div>
                        <div class="text mt-2">
                            {{ this.LANG_ARR.services[0].text_6 }}
                            <div class="subtext">
                                {{ this.LANG_ARR.services[0].subtext_3 }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="button">
                    <router-link class="cool_btn" @click="change_page('other_page', 4000)" to="/">
                        {{ this.LANG_ARR.services[0].button_3 }}
                    </router-link>
                </div>
            </div>
            <div class="service-container" id="scroll_4">
                <div class="title">
                    <div class="number px-md-4">
                        04
                    </div>
                    {{ this.LANG_ARR.services[0].title_4 }}
                </div>
                <div class="section_1">
                    <div class="section-item">
                        <div class="image">
                            <img style="width: 45px;" :src="require('../assets/images/yellow-star.png')" alt="" />
                        </div>
                        <div class="text mt-2" style="color: #54791A;">
                            {{ this.LANG_ARR.services[0].text_7 }}
                            <div class="subtext" style="color: #000;">
                                {{ this.LANG_ARR.services[0].subtext_4 }}
                            </div>
                        </div>
                    </div>
                    <div class="section-item">
                        <div class="image">
                            <img style="width: 45px;" :src="require('../assets/images/yellow-star.png')" alt="" />
                        </div>
                        <div class="text mt-2" style="color: #54791A;">
                            {{ this.LANG_ARR.services[0].text_8 }}
                            <div class="subtext" style="color: #000;">
                                {{ this.LANG_ARR.services[0].subtext_5 }}
                            </div>
                        </div>
                    </div>
                    <div class="section-item">
                        <div class="image">
                            <img style="width: 45px;" :src="require('../assets/images/yellow-star.png')" alt="" />
                        </div>
                        <div class="text mt-2" style="color: #54791A;">
                            {{ this.LANG_ARR.services[0].text_9 }}
                            <div class="subtext" style="color: #000;" v-html="this.LANG_ARR.services[0].subtext_6">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="button">
                    <router-link class="cool_btn" @click="change_page('other_page', 4000)" to="/">
                        {{ this.LANG_ARR.services[0].button_4 }}
                    </router-link>
                </div>
            </div>
            <div class="service-container" id="scroll_5">
                <div class="title">
                    <div class="number px-md-4">
                        05
                    </div>
                    {{ this.LANG_ARR.services[0].title_5 }}
                </div>
                <div class="section_1">
                    <div class="section-item">
                        <div class="image">
                            <img style="width: 45px;" :src="require('../assets/images/yellow-star.png')" alt="" />
                        </div>
                        <div class="text mt-2">
                            {{ this.LANG_ARR.services[0].text_10 }}
                        </div>
                    </div>
                    <div class="section-item">
                        <div class="image">
                            <img style="width: 45px;" :src="require('../assets/images/yellow-star.png')" alt="" />
                        </div>
                        <div class="text mt-2">
                            {{ this.LANG_ARR.services[0].text_11 }}
                        </div>
                    </div>
                    <div class="section-item">
                        <div class="image">
                            <img style="width: 45px;" :src="require('../assets/images/yellow-star.png')" alt="" />
                        </div>
                        <div class="text mt-2">
                            {{ this.LANG_ARR.services[0].text_12 }}
                        </div>
                    </div>
                    <div class="section-item">
                        <div class="image">
                            <img style="width: 45px;" :src="require('../assets/images/yellow-star.png')" alt="" />
                        </div>
                        <div class="text mt-2">
                            {{ this.LANG_ARR.services[0].text_13 }}
                        </div>
                    </div>
                </div>
                <div class="button">
                    <router-link class="cool_btn" @click="change_page('other_page', 4000)" to="/">
                        {{ this.LANG_ARR.services[0].button_5 }}
                    </router-link>
                </div>
            </div>
            <div class="service-container" id="scroll_6">
                <div class="title">
                    <div class="number px-md-4">
                        06
                    </div>
                    {{ this.LANG_ARR.services[0].title_6 }}
                </div>
                <div class="section_1">
                    <div class="section-item">
                        <div class="image">
                            <img style="width: 45px;" :src="require('../assets/images/yellow-star.png')" alt="" />
                        </div>
                        <div class="text mt-2">
                            {{ this.LANG_ARR.services[0].text_14 }}
                        </div>
                    </div>
                    <div class="section-item">
                        <div class="image">
                            <img style="width: 45px;" :src="require('../assets/images/yellow-star.png')" alt="" />
                        </div>
                        <div class="text mt-2">
                            {{ this.LANG_ARR.services[0].text_15 }}
                        </div>
                    </div>
                    <div class="section-item">
                        <div class="image">
                            <img style="width: 45px;" :src="require('../assets/images/yellow-star.png')" alt="" />
                        </div>
                        <div class="text mt-2">
                            {{ this.LANG_ARR.services[0].text_16 }}
                        </div>
                    </div>
                    <div class="section-item">
                        <div class="image">
                            <img style="width: 45px;" :src="require('../assets/images/yellow-star.png')" alt="" />
                        </div>
                        <div class="text mt-2">
                            {{ this.LANG_ARR.services[0].text_17 }}
                        </div>
                    </div>
                </div>
                <div class="button">
                    <router-link class="cool_btn" @click="change_page('other_page', 4000)" to="/">
                        {{ this.LANG_ARR.services[0].button_6 }}
                    </router-link>
                </div>
            </div>
            <div class="service-container" id="scroll_7">
                <div class="title">
                    <div class="number px-md-4">
                        07
                    </div>
                    {{ this.LANG_ARR.services[0].title_7 }}
                </div>
                <div class="section_3">
                    <div class="section-item">
                        <div class="image">
                            <img :src="require('../assets/images/yellow-star.png')" alt="" />
                            <!--                             <div class="vertical-text">
                                {{ this.LANG_ARR.services[0].v_text_1 }}
                            </div> -->
                        </div>
                        <div class="text mt-2" v-html="this.LANG_ARR.services[0].text_18">
                        </div>
                    </div>
                    <div class="section-item">
                        <div class="image">
                            <img :src="require('../assets/images/yellow-star.png')" alt="" />
                            <!--                             <div class="vertical-text">
                                {{ this.LANG_ARR.services[0].v_text_2 }}
                            </div> -->
                        </div>
                        <div class="text mt-2" v-html="this.LANG_ARR.services[0].text_19">
                        </div>
                    </div>
                    <div class="section-item">
                        <div class="image">
                            <img :src="require('../assets/images/yellow-star.png')" alt="" />
                            <!--                             <div class="vertical-text">
                                {{ this.LANG_ARR.services[0].v_text_3 }}
                            </div> -->
                        </div>
                        <div class="text mt-2" v-html="this.LANG_ARR.services[0].text_20">
                        </div>
                    </div>
                </div>
                <div class="button">
                    <router-link class="cool_btn" @click="change_page('other_page', 4000)" to="/">
                        {{ this.LANG_ARR.services[0].button_7 }}
                    </router-link>
                </div>
            </div>
            <div class="service-container" id="scroll_8">
                <div class="title">
                    <div class="number px-md-4">
                        08
                    </div>
                    {{ this.LANG_ARR.services[0].title_8 }}
                </div>
                <div class="section_1">
                    <div class="section-item">
                        <div class="image">
                            <img :src="require('../assets/images/yellow-star.png')" alt="" />
                        </div>
                        <div class="text mt-2" v-html="this.LANG_ARR.services[0].text_21">
                        </div>
                    </div>
                </div>
                <div class="button">
                    <router-link class="cool_btn" @click="change_page('other_page', 4000)" to="/">
                        {{ this.LANG_ARR.services[0].button_8 }}
                    </router-link>
                </div>
            </div>
        </div>
        <div class="parallax-container desctop">
            <div class="texture"></div>
            <img class="bg-sun" :src="require('../assets/images/bg-sun.png')" alt="" />
            <div class="container">
                <div class="title">
                    {{ this.LANG_ARR.services[0].title_9 }}
                </div>
                <div class="button d-flex">
                    <router-link class="cool_btn-white" @click="change_page('other_page', 4000)" to="/">
                        {{ this.LANG_ARR.services[0].button_9 }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "services",
    mounted() {
        let scroll_btn = document.querySelector(".main-description");
        scroll_btn.addEventListener("click", function (e) {
            const id = e.target.getAttribute("data-scroll");
            document.querySelector(`#${id}`).scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
        })
        /*         for (let item of scroll_btn) {
                    item.addEventListener("click", function (e) {
                        e.preventDefault();
        
                    });
                }; */
    },
    methods: {
        ...mapActions(["SCROLL"]),
        change_page(id, offset) {
            this.SCROLL(id);
            if (this.GO_TO != "other_page" && this.GO_TO != "home") {
                setTimeout(() => this.scrollTo(offset), 100);
            } else {
                this.scrollTo(offset);
            }
        },
        scrollTo(offset) {
            const screenWidth = window.screen.width
            if (screenWidth < 1024) {
                let mob_offset = offset - 2000
                window.scrollTo(0, mob_offset);
            } else {
                window.scrollTo(0, offset);
            }
        },
    },
    computed: {
        ...mapGetters(["GO_TO", "LANGUAGE", "LANG_ARR"]),
    },
}

</script>

<style lang="scss" scoped>
.img-title {
    margin: 120px 0 50px;
    display: flex;
    justify-content: space-between;
    border-top: 5px solid #201e1c;
    border-bottom: 5px solid #201e1c;
    padding: 30px 0;
}

.main-description {
    width: 50%;
    margin: 0 auto;
    color: #54791A;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 175%;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;


}




.service-container {
    .title {
        width: 80%;
        margin: 0 auto;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-style: italic;
        font-weight: 700;
        font-size: 64px;
        line-height: 78px;
        color: #000;

        .number {
            color: #FF6B00;
            font-style: normal;
            font-weight: 700;
            font-size: 160px;
            line-height: 195px;
        }
    }

    .section_1 {
        margin: 50px 0;
        border: 2px solid #000;
        padding: 20px 40px;

        .section-item {
            margin: 25px 0;
            display: flex;
            /* align-items: center; */
            /* justify-content: space-between; */

            .text {
                padding-left: 20px;
                font-style: normal;
                font-weight: 400;
                font-size: 28px;
                line-height: 29px;
                color: #000;

                .subtext {
                    margin-top: 25px;
                    font-size: 18px !important;
                    line-height: 20px !important;
                }
            }
        }
    }

    .section_2 {
        margin: 50px 0;
        border: 2px solid #000;
        padding: 20px 40px;
        display: flex;
        justify-content: space-evenly;

        .section-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .text {
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 49px;
                color: #54791A;
            }

            .subtext {
                font-style: italic;
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                color: #000000;
            }
        }
    }

    .section_3 {
        margin: 50px 0;
        border: 2px solid #000;
        padding: 20px 40px;

        .section-item {
            margin: 25px 0;
            display: flex;

            .vertical-text {
                color: #FFCD1C;
                transform: rotate(-90deg);
                width: 150px;
                font-weight: 700;
                font-size: 25px;
                line-height: 30px;
                -webkit-text-stroke: 1px rgb(66, 66, 66);
            }

            .text {
                padding-left: 20px;
                font-style: normal;
                font-weight: 400;
                font-size: 28px;
                line-height: 29px;
                color: #000;


                .subtext {
                    margin-top: 25px;
                    font-size: 18px !important;
                    line-height: 20px !important;
                }
            }
        }
    }

    .button {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0 0 60px 0;
    }
}

.parallax-container {
    background: #201e1c;
    position: relative;
    padding: 0 0 150px;
    overflow: hidden;
    height: 600px;

    .container {
        margin-top: -700px;

        .title {
            font-style: italic;
            font-weight: 700;
            font-size: 40px;
            line-height: 49px;
            color: #FFFFFF;
        }
    }
}

.bg-sun {
    margin-top: -100px;
    margin-left: 20%;
}

.texture {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("../assets/images/Dirty-Surface-Texture-03.png");
    opacity: 0.7;
}




.cool_btn {
    position: relative;
    padding: 10px 30px;
    margin: 45px auto;
    color: #54791A;
    border: transparent;
    text-decoration: none;
    font-size: 20px;
    text-transform: uppercase;
    transition: 0.5s;
    background: transparent;

    &::before {
        content: " ";
        position: absolute;
        top: -1px;
        left: -1px;
        width: 10px;
        height: 10px;
        border-top: 2px solid #54791A;
        border-left: 2px solid #54791A;
        transition: 0.5s;
        transition-delay: 0.5s;
    }

    &::after {
        content: " ";
        position: absolute;
        bottom: -1px;
        right: -1px;
        width: 10px;
        height: 10px;
        border-bottom: 2px solid #54791A;
        border-right: 2px solid #54791A;
        transition: 0.5s;
        transition-delay: 0.5s;
    }

    &:hover::before {
        width: 100%;
        height: 100%;
        transition-delay: 0s;
    }

    &:hover::after {
        width: 100%;
        height: 100%;
        transition-delay: 0s;
    }

    &:hover {
        background: #54791A;
        color: #fff;
        /* box-shadow: 0 0 50px #54791A; */
        transition-delay: 0.5s;
    }
}

.cool_btn-white {
    position: relative;
    padding: 10px 30px;
    margin: 45px auto;
    color: #fff;
    border: transparent;
    text-decoration: none;
    font-size: 20px;
    text-transform: uppercase;
    transition: 0.5s;
    background: transparent;

    &::before {
        content: " ";
        position: absolute;
        top: -1px;
        left: -1px;
        width: 10px;
        height: 10px;
        border-top: 2px solid #fff;
        border-left: 2px solid #fff;
        transition: 0.5s;
        transition-delay: 0.5s;
    }

    &::after {
        content: " ";
        position: absolute;
        bottom: -1px;
        right: -1px;
        width: 10px;
        height: 10px;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
        transition: 0.5s;
        transition-delay: 0.5s;
    }

    &:hover::before {
        width: 100%;
        height: 100%;
        transition-delay: 0s;
    }

    &:hover::after {
        width: 100%;
        height: 100%;
        transition-delay: 0s;
    }

    &:hover {
        background: #fff;
        color: #54791A;
        /* box-shadow: 0 0 50px #54791A; */
        transition-delay: 0.5s;
    }
}



@media screen and (max-width: 1400px) {
    .img-title {
        margin: 60px 0;

        img {
            &:nth-child(1) {
                width: 15%;
            }

            &:nth-child(2) {
                width: 60%;
            }

            &:nth-child(3) {
                width: 15%;
            }
        }
    }

}


@media screen and (max-width: 1000px) {
    .main-description {
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .img-title {
        margin: 0 0 60px;
    }

    .main-description {
        display: none;
    }

    .service-container {
        .title {
            font-size: 30px;
            line-height: 30px;
            text-align: start;

            .number {
                font-size: 100px;
                margin-right: -60px;
                margin-top: -20px;
            }
        }

        .section_1 {
            padding: 10px;
            margin: 0 !important;

            .section-item {
                .text {
                    font-size: 20px;
                    line-height: 20px;

                    .subtext {
                        font-size: 14px !important;
                        line-height: 15px !important;
                    }
                }
            }
        }

        .section_2 {
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 20px 0;

            .section-item {
                margin-top: 15px;

                .text {
                    font-size: 30px;
                    line-height: 30px;
                }

                .subtext {
                    font-size: 16px;
                }

                img {
                    width: 40px !important;
                }

            }
        }

        .section_3 {
            padding: 20px 10px;

            .section-item {
                margin-top: 15px;

                .text {
                    font-size: 20px;
                    line-height: 20px;
                }

                .subtext {
                    font-size: 16px;
                }

                img {
                    width: 40px !important;
                }

            }
        }

        .button {
            margin: 0 !important;

            .cool_btn {
                padding: 10px;
            }
        }

    }

    .parallax-container {
        .container {
            .title {
                font-size: 20px;
                line-height: 29px;
                text-align: center;
            }
        }
    }
}
</style>