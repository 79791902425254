<template>
  <div id="viking">
    <div class="container">
      <div class="arrow">
        <img :src="require('../assets/images/arrow_bottom.svg')" alt="" />
      </div>
      <img
        class="viking"
        :src="require('../assets/images/headnew3.png')"
        alt=""
      />
      <div class="text" v-html="this.LANG_ARR.viking[0].title">
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "viking",
  computed: {
    ...mapGetters(["LANG_ARR"]),
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  .viking {
    width: 100%;
  }
  .arrow {
    position: absolute;
    top: 30%;
    left: 20%;
  }
  .text {
    position: absolute;
    top: 35%;
    left: 72%;
    font-style: italic;
    font-weight: 500;
    font-size: 20px;
    line-height: 143.9%;
    /* or 29px */

    color: #201e1c;
  }
}

@media screen and (max-width: 1400px) {
  .arrow {
    left: 17% !important;
    img {
      width: 60%;
      height: 60%;
    }
  }
  .text {
    left: 75% !important;
    font-size: 18px !important;
  }
}

@media screen and (max-width: 1000px) {
  .arrow {
    left: 19% !important;
    img {
      width: 60%;
      height: 60%;
    }
  }
  .text {
    left: 75% !important;
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1000px) {
  .arrow {
    img {
      width: 30%;
      height: 30%;
    }
  }
  .text {
    left: 75% !important;
    font-size: 10px !important;
  }
}
</style>