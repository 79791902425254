<template>
  <div id="who-are">
    <div class="img-title">
      <img :src="require('../assets/images/who-are-left-title.png')" alt="" />
      <img :src="require(`../assets/images/who-are-center-title_${this.LANG_ARR.who_we_are[0].image}.png`)" alt="" />
      <img :src="require('../assets/images/who-are-right-title.png')" alt="" />
    </div>
    <div class="container">
      <div class="who-are-we row justify-content-between">
        <div class="col-12 col-lg-6 right-aside">
          <div class="title">{{ this.LANG_ARR.who_we_are[0].title }}</div>
          <div class="description">
            {{ this.LANG_ARR.who_we_are[0].description1 }}
          </div>
          <div class="image">
            <img
              :class="{ rotateHorse: isHorse }"
              class="horse"
              :src="require('../assets/images/horse.png')"
              alt=""
            />
            <img
              class="circle"
              :src="require('../assets/images/yellow-circle.png')"
              alt=""
            />
          </div>
        </div>
        <div class="col-12 col-lg-5 photo-container">
          <div class="photo">
            <img :src="require('../assets/images/who-are-photo.png')" alt="" />
          </div>
          <div class="description">
            {{ this.LANG_ARR.who_we_are[0].description2 }}
            <br />
            <br />
            {{ this.LANG_ARR.who_we_are[0].description3 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "who-are",
  data() {
    return {
      isHorse: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.offset);
    window.addEventListener("scroll", this.offset_mobile);
  },
  methods: {
    offset() {
      if (pageYOffset >= 800) {
        this.isHorse = true;
      } else {
        this.isHorse = false;
      }
    },
    offset_mobile() {
      if (pageYOffset >= 300) {
        this.isHorse = true;
      } else {
        this.isHorse = false;
      }
    },
  },
  computed: {
    ...mapGetters(["LANG_ARR"]),
  },
};
</script>

<style lang="scss" scoped>
.img-title {
  margin: 120px 0 180px;
  display: flex;
  justify-content: space-between;
  border-top: 5px solid #201e1c;
  border-bottom: 5px solid #201e1c;
  padding: 30px 0;
}

.who-are-we {
  .title {
    font-style: italic;
    font-weight: bold;
    font-size: 80px;
    line-height: 143.9%;
    color: #201e1c;
  }
  .description {
    margin: 30px 0;
    width: 70%;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 143.9%;
  }
  .image {
    position: relative;
    width: 500px;
    height: 500px;
    img {
      width: 500px;
    }
    .circle {
      z-index: 1;
      position: absolute;
      top: 63%;
    }
    .horse {
      z-index: 2;
      position: absolute;
    }
    .rotateHorse {
      animation: rotate-horse 0.5s linear;
    }
    @keyframes rotate-horse {
      0% {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(180deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .photo-container {
    .photo {
      display: flex;
      justify-content: flex-end;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .description {
      width: 100%;
      margin: 50px 0;
    }
  }
}

@media screen and (max-width: 1400px) {
  .img-title {
    margin: 60px 0;
    img {
      &:nth-child(1) {
        width: 15%;
      }
      &:nth-child(2) {
        width: 60%;
      }
      &:nth-child(3) {
        width: 15%;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .who-are-we {
    .title {
      font-size: 80px;
      text-align: center;
    }
    .description {
      margin: 30px 0;
      width: 100%;
      text-align: center;
      font-style: normal;
      font-size: 20px;
    }
    .image {
      position: relative;
      width: 500px;
      height: 500px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .photo-container {
      display: flex;
      flex-direction: column-reverse;
      margin: 0 auto 80px;
    }
  }
}

@media screen and (max-width: 600px) {
  .img-title {
    margin: 0 0 60px;
  }
  .who-are-we {
    .title {
      font-size: 30px;
      text-align: center;
      /* display: none; */
    }
    .description {
      margin: 30px 0;
      width: 100%;
      text-align: center;
      font-style: normal;
      font-size: 16px;
    }
    .image {
      position: relative;
      width: 320px;
      height: 320px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .photo-container {
      .photo {
        display: flex;
        justify-content: flex-end;
        margin: 0 auto 40px;
        img {
          width: 90%;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>