<template>
  <div id="team">
    <div class="img-title">
      <img :src="require('../assets/images/team-left-title.png')" alt="" />
      <img :src="require(`../assets/images/team-center-title_${this.LANG_ARR.team[0].image}.png`)" alt="" />
      <img :src="require('../assets/images/team-right-title.png')" alt="" />
    </div>
    <div class="container">
      <div class="image">
        <img :src="require('../assets/images/team-sun.png')" alt="" />
        <div class="description">
          {{ this.LANG_ARR.team[0].main1 }}
          <br />
          {{ this.LANG_ARR.team[0].main2 }}
          <br />
          <br />
          {{ this.LANG_ARR.team[0].main3 }}
        </div>
      </div>
      <div class="row justify-content-between align-items-center">
        <div class="col-12 col-xl-6 photo order-last order-xl-first">
          <img :src="require('../assets/images/bogdi.png')" alt="" />
        </div>
        <div class="col-12 col-xl-5 text">
          <img :src="require('../assets/images/crown.png')" alt="" />
          <div class="title">
            {{ this.LANG_ARR.team[0].teammate1_title }}
            <br />
            <span>{{ this.LANG_ARR.team[0].teammate1 }}</span>
          </div>
          <div class="description">
            {{ this.LANG_ARR.team[0].credo1 }}
          </div>
        </div>
      </div>
      <div class="row justify-content-between align-items-center">
        <div class="col-12 col-xl-5 text">
          <img :src="require('../assets/images/axe.png')" alt="" />
          <div class="title">
            {{ this.LANG_ARR.team[0].teammate2_title }}
            <br />
            <span>{{ this.LANG_ARR.team[0].teammate2 }}</span>
          </div>
          <div class="description" v-html="this.LANG_ARR.team[0].credo2"></div>
        </div>
        <div class="col-12 col-xl-6 photo">
          <img :src="require('../assets/images/katya.png')" alt="" />
        </div>
      </div>
      <div class="row justify-content-between align-items-center">
        <div class="col-12 col-xl-6 photo order-last order-xl-first">
          <img :src="require('../assets/images/bond.png')" alt="" />
        </div>
        <div class="col-12 col-xl-5 text">
          <img :src="require('../assets/images/helm.png')" alt="" />
          <div class="title">
            {{ this.LANG_ARR.team[0].teammate3_title }}
            <br />
            <span>{{ this.LANG_ARR.team[0].teammate3 }}</span>
          </div>
          <div class="description">
            {{ this.LANG_ARR.team[0].credo3 }}
          </div>
        </div>
      </div>
      <div class="row justify-content-between align-items-center">
        <div class="col-12 col-xl-5 text">
          <img :src="require('../assets/images/shield.png')" alt="" />
          <div class="title">
            {{ this.LANG_ARR.team[0].teammate4_title }}
            <br />
            <span>{{ this.LANG_ARR.team[0].teammate4 }}</span>
          </div>
          <div class="description">
            {{ this.LANG_ARR.team[0].credo4 }}
          </div>
        </div>
        <div class="col-12 col-xl-6 photo">
          <img :src="require('../assets/images/masha.png')" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "team",
  computed: {
    ...mapGetters(["LANG_ARR"]),
  },
};
</script>

<style lang="scss" scoped>
.img-title {
  margin: 120px 0 180px;
  display: flex;
  justify-content: space-between;
  border-top: 5px solid #201e1c;
  border-bottom: 5px solid #201e1c;
  padding: 30px 0;
}

.image {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  img {
    width: 50%;
    margin: 0 auto;
  }
  .description {
    margin: 80px 0;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    color: #201e1c;
  }
}

.photo {
  display: flex;
  justify-content: center;
  img {
    width: 80%;
  }
}

.row {
  padding: 80px 0;
}

.text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    margin: 20px 0;
  }
  .title {
    font-style: italic;
    font-weight: 700;
    font-size: 30px;
    color: #201e1c;
    text-align: center;
    text-transform: uppercase;
    border-bottom: 2px solid #000;
    padding: 0 0 20px;
    span {
      font-weight: 400;
    }
  }
  .description {
    margin: 20px 0;
    font-style: italic;
    font-weight: 500;
    font-size: 20px;
    line-height: 143.9%;
    text-align: center;
    color: #201e1c;
  }
}

@media screen and (max-width: 1400px) {
  .img-title {
    margin: 60px 0;
    img {
      &:nth-child(1) {
        width: 15%;
      }
      &:nth-child(2) {
        width: 60%;
      }
      &:nth-child(3) {
        width: 15%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .img-title {
    margin: 0 0 60px;
  }
  .text {
    .title {
      font-size: 20px;
    }
    .description {
      font-size: 16px;
    }
  }
  .image {
    .description {
      font-size: 20px;
      margin-bottom: 0;
    }
  }
  .row {
    padding: 40px 0;
  }
}
</style>